<template>
  <v-layout v-resize="onResize" class="d-flex flex-column">
    <v-sheet color="white" class="px-4 rounded-t">
      <div class="d-flex align-center pt-2 pb-4">
        <div class="d-flex align-start mr-4">
          <v-icon x-large color="primary">{{ tableIcon }}</v-icon>
        </div>
        <span class="text-h6 font-weight-regular">
          {{
          `${tableName}${event ? " - " + event.text : ""}`
          }}
        </span>

        <v-spacer></v-spacer>

        <v-tooltip
          top
          v-if="
            this.selectedRowId !== null &&
            !Array.isArray(this.selectedRowId) &&
            showDuplicateOrDeleteItemBtn
          "
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="openDialogDuplicateItem()"
            >
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <span>Duplicar item selecionado</span>
        </v-tooltip>

        <v-dialog
          v-model="dialogDuplicateItem"
          max-width="900px"
          persistent
          no-click-animation
          style="z-index: 2000 !important"
        >
          <v-card style="position: relative">
            <v-card-title
              class="light--text"
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 1000"
            >
              <span ref="formTitle" class="headline">Duplicar item</span>

              <v-spacer></v-spacer>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="pa-0"
                    @click="dialogDuplicateItem = false"
                    min-width="48px"
                    text
                    dark
                  >
                    <v-icon color="light">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text class="ma-o pa-0">
              <v-container class="pa-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      :value="dialogDuplicateItemAccountGroup"
                      label="Conta gerêncial *"
                      class="pa-0 mr-4"
                      style="
                        min-width: 300px !important;
                        z-index: 1000 !important;
                      "
                      readonly
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      :value="dialogDuplicateItemChartAccount"
                      label="Conta contábil *"
                      class="pa-0 mr-4"
                      style="
                        min-width: 300px !important;
                        z-index: 1000 !important;
                      "
                      readonly
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="dialogDuplicateItemDescription"
                      label="Justificativa da despesa *"
                      class="pa-0 mr-4"
                      style="
                        min-width: 300px !important;
                        z-index: 1000 !important;
                      "
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="light" style="position: sticky; bottom: 0; width: 100%">
              <v-divider class="mx-4"></v-divider>
              <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogDuplicateItem = false"
                  class="mr-4"
                >Cancelar</v-btn>

                <v-btn
                  color="primary"
                  @click="duplicateItem((save = false))"
                  class="mr-4"
                  :disabled="!dialogDuplicateItemDescription.length"
                  :loading="duplicatingItem"
                >Salvar</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip top v-if="this.selectedRowId !== null && showDuplicateOrDeleteItemBtn">
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="openDialogMassDelete()"
            >
              <v-icon>mdi-delete-sweep</v-icon>
            </v-btn>
          </template>
          <span>Deletar itens selecionados</span>
        </v-tooltip>

        <v-dialog v-model="dialogMassDelete" max-width="600px">
          <v-card>
            <div class="d-flex justify-center">
              <v-card-title
                class="headline text-center"
              >Tem certeza de que deseja excluir estes itens?</v-card-title>
            </div>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogMassDelete = false" class="mr-4">Cancelar</v-btn>
              <v-btn color="primary" @click="massDeleteItemConfirm" :loading="deleting">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="openNewEmployeeDialog()"
              :disabled="
                disableActionButtons || purpose.id !== 41 || isApprovedEvent
              "
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar funcionários</span>
        </v-tooltip>

        <v-dialog
          v-model="newEmployeeDialog"
          max-width="1200px"
          persistent
          no-click-animation
          style="z-index: 2000 !important"
        >
          <v-card style="position: relative">
            <v-card-title
              class="light--text"
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 1000"
            >
              <span ref="formTitle" class="headline">Adicionar funcionários</span>

              <v-spacer></v-spacer>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="pa-0"
                    @click="newEmployeeDialog = false"
                    min-width="48px"
                    text
                    dark
                  >
                    <v-icon color="light">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text class="ma-o pa-0">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table
                      v-model="selectedNewEmployees"
                      :headers="newEmployeeHeaders"
                      :items="
                        searchNewEmployee && searchNewEmployee.length
                          ? filteredNewEmployeeItems
                          : newEmployeeItems
                      "
                      item-key="id"
                      show-select
                      class="elevation-0 text-no-wrap"
                      :loading="loadingEmployeeDialog"
                      :single-select="loadingEmployeeDialog"
                      :items-per-page="50"
                      :footer-props="footerProps"
                      fixed-header
                      :height="432"
                      checkbox-color="primary"
                    >
                      <template v-slot:top>
                        <v-row no-gutters class="pa-4 d-flex align-center">
                          <v-col cols="7" class="d-flex align-center justify-start">
                            <span class="text-subtitle-1 mr-2">Selecionados:</span>
                            <span class="primary--text text-subtitle-1 mr-2">
                              {{
                              `(${selectedNewEmployees.length})`
                              }}
                            </span>
                            <div
                              v-for="(employee, index) in selectedNewEmployees"
                              :key="employee.id"
                              style="
                                display: inline-block;
                                white-space: nowrap;
                                overflow: hidden !important;
                                text-overflow: ellipsis;
                              "
                            >
                              <div v-if="index >= 0 && index <= 2">
                                <span>{{ employee.text }}</span>
                                <span
                                  v-if="
                                    index !== selectedNewEmployees.length - 1
                                  "
                                  class="mx-2 primary--text"
                                >|</span>
                              </div>

                              <div v-else-if="index === 3">
                                <span class="mx-2 primary--text">...</span>
                              </div>
                            </div>
                          </v-col>

                          <v-spacer></v-spacer>

                          <v-col cols="4">
                            <v-text-field
                              v-model="searchNewEmployee"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              hide-details
                              single-line
                              dense
                              clearable
                              class="mr-4"
                            />
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="light" style="position: sticky; bottom: 0; width: 100%">
              <v-divider class="mx-4"></v-divider>
              <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="newEmployeeDialog = false" class="mr-4">Cancelar</v-btn>
                <v-btn
                  color="primary"
                  @click="saveNewEmployeeDialog()"
                  :loading="savingNewEmployees"
                  :disabled="!selectedNewEmployees.length"
                >Salvar</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="openReadjustmentDialog()"
              :disabled="
                disableActionButtons ||
                !isTotalPurpose ||
                calculatingReadjustment ||
                isShowingFormulas ||
                isApprovedEvent
              "
            >
              <v-icon>mdi-format-list-group-plus</v-icon>
            </v-btn>
          </template>
          <span>Criar linhas de reajuste</span>
        </v-tooltip>

        <v-dialog
          v-model="dialogReadjustment"
          max-width="900px"
          persistent
          no-click-animation
          style="z-index: 2000 !important"
        >
          <v-card style="position: relative">
            <v-card-title
              class="light--text"
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 1000"
            >
              <span ref="formTitle" class="headline">Criar linhas de reajuste</span>

              <v-spacer></v-spacer>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="pa-0"
                    @click="dialogReadjustment = false"
                    min-width="48px"
                    text
                    dark
                    :disabled="calculatingReadjustment"
                  >
                    <v-icon color="light">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text class="ma-o pa-0">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                      <v-autocomplete
                        v-model="dialogReadjustmentEmployee"
                        :items="loadingEmployee ? [] : employeeOption.items"
                        label="Escolha um ou mais colaboradores (opcional)"
                        multiple
                        :loading="loadingEmployee"
                        hide-details
                        class="pa-0 my-4"
                        style="
                          min-width: 300px !important;
                          z-index: 1000 !important;
                        "
                        return-object
                        :no-data-text="
                          loadingEmployee
                            ? 'Atualizando dados...'
                            : 'Não há dados disponíveis'
                        "
                        :disabled="calculatingReadjustment"
                      >
                        <template v-slot:prepend-item v-if="!loadingEmployee">
                          <template v-if="loadingEmployee">
                            <v-list-item :ripple="false" @mousedown.prevent>
                              <v-list-item-content>
                                <v-list-item-title>Atualizando dados...</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>

                          <template v-else-if="employeeOption.items.length">
                            <v-list-item
                              :ripple="false"
                              @mousedown.prevent
                              @click="
                                setAllEmployees('dialogReadjustmentEmployee')
                              "
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="!employee.length ? 'gray' : 'primary'"
                                >{{ selectAllDialogReadjustmentEmployeeIcon }}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>--- TODOS ---</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </template>

                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              dialogReadjustmentEmployee.length ===
                                employeeOption.items.length && index === 0
                            "
                          >--- TODOS ---</span>

                          <span
                            v-else-if="
                              dialogReadjustmentEmployee.length !==
                              employeeOption.items.length
                            "
                            style="
                              display: inline-block;
                              white-space: nowrap;
                              overflow: hidden !important;
                              text-overflow: ellipsis;
                            "
                          >
                            <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                            <span
                              v-if="index === 1"
                              class="text-caption primary--text mr-1"
                              style="max-width: 20px !important"
                            >{{ `+ ${dialogReadjustmentEmployee.length - 1}` }}</span>
                          </span>
                        </template>
                      </v-autocomplete>
                    </div>

                    <p class="text-h6 text-center mt-8 mb-4">{{ dialogReadjustmentText }}</p>

                    <div v-if="calculatingReadjustment" class="text-center" style="width: 100%">
                      <v-progress-circular
                        indeterminate
                        size="24"
                        width="3"
                        color="primary"
                        class="mb-4"
                      ></v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <div class="light" style="position: sticky; bottom: 0; width: 100%">
              <v-divider class="mx-4"></v-divider>
              <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogReadjustment = false"
                  :disabled="calculatingReadjustment"
                  class="mr-4"
                >Cancelar</v-btn>

                <v-btn
                  :color="unsavedChanges ? 'secondary' : 'primary'"
                  :text="unsavedChanges"
                  @click="calculateReadjustment((save = false))"
                  class="mr-4"
                  :disabled="calculatingReadjustment"
                >{{ `Continuar${unsavedChanges ? " sem salvar" : ""}` }}</v-btn>

                <v-btn
                  v-if="unsavedChanges"
                  color="primary"
                  @click="calculateReadjustment((save = true))"
                  :disabled="calculatingReadjustment"
                >Salvar e continuar</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              :disabled="
                disableActionButtons || isBudgetOrActualEvent || isApprovedEvent
              "
              @click="updateMonthsDialog = true"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Atualizar meses realizados</span>
        </v-tooltip>

        <v-dialog v-model="updateMonthsDialog" max-width="450px">
          <v-card>
            <div class="d-flex justify-center">
              <v-card-title class="title">Você deseja realizar essa atualização?</v-card-title>
            </div>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="updateMonthsDialog = false" class="mr-4">Cancelar</v-btn>
              <v-btn
                color="primary"
                @click="updateMonths()"
                :loading="loadingUpdateMonths"
              >Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="refresh()"
              :disabled="disableActionButtons || isNotShowingAnyColumn"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Atualizar</span>
        </v-tooltip>

        <v-dialog v-model="dialogRefreshGrid" max-width="850px">
          <v-card>
            <div class="d-flex justify-center">
              <v-card-title class="headline">
                Você possui edições não salvas. Tem certeza de que deseja
                continuar?
              </v-card-title>
            </div>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
              <v-btn
                color="secondary"
                text
                @click="(editedRows = []), refresh()"
                class="mr-4"
              >Continuar sem salvar</v-btn>
              <v-btn color="primary" @click="refresh((save = true))">Salvar e continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-4 pa-0"
              @click="saveData()"
              v-on="on"
              min-width="48px"
              :color="unsavedChanges ? 'secondary' : 'primary'"
              :disabled="disableSave"
              :loading="saving"
            >
              <v-icon>
                {{
                unsavedChanges || isShowingFormulas
                ? "mdi-content-save-alert"
                : "mdi-content-save"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>Salvar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="dialogCopyValues = true"
              :disabled="disableActionButtons || actualLevel.id_status === 4"
            >
              <v-icon>mdi-database-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>Copiar dados</span>
        </v-tooltip>

        <v-dialog v-model="dialogCopyValues" max-width="800" max-height="450" style="z-index: 1000">
          <v-card style="position: relative">
            <v-toolbar
              dark
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 5"
              elevation="0"
            >
              <v-toolbar-title>
                <span>Copiar dados</span>
              </v-toolbar-title>
              <v-spacer/>

              <v-tooltip bottom>
                <template v-slot:activator="on">
                  <v-btn
                    color="transparent"
                    v-on="on"
                    min-width="48px"
                    @click="closeDialogCopyValues()"
                    depressed
                  >
                    <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container class="py-4 mb-4">
              <v-layout align-center justify-center wrap>
                <v-flex xs5>
                  <v-autocomplete
                    v-model="eventRefCopyValues"
                    :items="eventOption.items"
                    label="Evento de referência"
                    prefix="De: "
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs1>
                  <v-layout justify-center>
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-layout>
                </v-flex>
                <v-flex xs5>
                  <v-autocomplete
                    :value="event"
                    readonly
                    :items="eventOption.items"
                    label="Evento"
                    prefix="Para: "
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs5>
                  <v-autocomplete
                    v-model="purposeRefCopyValues"
                    :items="purposeOption.items"
                    label="Propósito de referência"
                    prefix="De: "
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs1>
                  <v-layout justify-center>
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-layout>
                </v-flex>
                <v-flex xs5>
                  <v-autocomplete
                    :value="purpose"
                    readonly
                    :items="purposeOption.items"
                    label="Propósito"
                    prefix="Para: "
                    return-object
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
            <div class="light" style="position: sticky; bottom: 0; width: 100%">
              <v-divider class="mx-4"></v-divider>
              <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialogCopyValues()" color="primary" text class="mr-4">Cancelar</v-btn>
                <v-btn
                  @click="copyValues()"
                  color="primary"
                  :loading="copyingValues"
                  :disabled="!eventRefCopyValues || !purposeRefCopyValues"
                >Salvar</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="dialogRatio = true"
              :disabled="disableActionButtons"
            >
              <v-icon>mdi-clipboard-flow</v-icon>
            </v-btn>
          </template>
          <span>Executar rateios</span>
        </v-tooltip>

        <v-dialog v-model="dialogRatio" max-width="800" max-height="450" style="z-index: 1000">
          <v-card style="position: relative">
            <v-toolbar
              dark
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 5"
              elevation="0"
            >
              <v-toolbar-title>
                <span>Executar rateios</span>
              </v-toolbar-title>
              <v-spacer/>

              <v-tooltip bottom>
                <template v-slot:activator="on">
                  <v-btn
                    color="transparent"
                    class="mt-2"
                    v-on="on"
                    min-width="48px"
                    @click="closeDialogRatio()"
                    depressed
                  >
                    <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-layout justify-space-between wrap>
                <v-flex xs12>
                  <v-select
                    v-model="event"
                    :items="eventOption.items"
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Evento"
                    @change="selectEvent()"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="ratioMonth"
                    :items="ratioMonths"
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Mês"
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-data-table
                    :headers="headersRatio"
                    :items="
                      purposeOption.items.filter((item) => item.matrix === 1)
                    "
                    :loading="loading"
                    item-key="id"
                    class="pb-2"
                    ref="dTable"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.text="{ item }">
                      <v-layout>
                        <v-flex>{{ item.text }}</v-flex>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="runRatio(item, false)"
                          depressed
                          small
                          class="primary mr-2"
                          :loading="loadingRatio"
                          :disabled="loadingRatio || !ratioMonth"
                        >Executar rateio</v-btn>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              :disabled="
                disableActionButtons || unsavedChanges || isApprovedEvent
              "
              @click="importSheet()"
              :loading="importing"
            >
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
            <input ref="uploader" class="d-none" type="file" @change="onFileChanged">
          </template>
          <span>Importar Excel</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              :disabled="disableActionButtons || unsavedChanges"
              @click="exportSheet()"
              :loading="exporting"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Exportar Excel</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="drawer = true"
              :disabled="
                disableActionButtons ||
                hasMultipleSelection ||
                hasEmptyRequiredFilter
              "
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-btn>
          </template>
          <span>Comentários</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="dialogApproval = true"
              :disabled="disableActionButtons || purpose.length > 1"
            >
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-btn>
          </template>
          <span>Aprovar</span>
        </v-tooltip>

        <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000">
          <v-card style="position: relative">
            <v-toolbar
              dark
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 5"
              elevation="0"
            >
              <v-toolbar-title v-if="actualLevel !== null">
                <span
                  v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"
                >Aprovar nível - {{ actualLevel.id_level }}</span>
                <span v-else-if="actualLevel.id_status === 4">Todos os níveis aprovados</span>
                <span v-else>Nenhum nível cadastrado</span>
              </v-toolbar-title>
              <v-spacer/>

              <v-tooltip bottom>
                <template v-slot:activator="on">
                  <v-btn
                    color="transparent"
                    class="mt-2"
                    v-on="on"
                    min-width="48px"
                    @click="(dialogApproval = false), (justification = '')"
                    depressed
                  >
                    <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-layout justify-end wrap>
                <v-flex xs12>
                  <v-select
                    v-model="selectedEvent"
                    :items="
                      options.find((option) => option.column === 'id_event')
                        .items
                    "
                    readonly
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Evento"
                    persistent-hint
                  ></v-select>
                  <v-select
                    v-model="selectedPurpose"
                    :items="
                      options.find((option) => option.column === 'id_purpose')
                        .items
                    "
                    readonly
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Propósito"
                    persistent-hint
                  ></v-select>
                  <v-select
                    v-model="selectedPurpose"
                    :items="
                      options.find(
                        (option) => option.column === 'id_cost_center'
                      ).items
                    "
                    readonly
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id_cost_center"
                    label="Centro de custo"
                    persistent-hint
                  ></v-select>

                  <v-text-field v-model="justification" class="mx-2 mt-1" label="Justificativa"></v-text-field>
                </v-flex>
                <v-btn
                  class="my-2 mr-2"
                  color="error"
                  @click="approvalSelect(0)"
                  :loading="loadingApproval"
                  :disabled="
                    disableApprove ||
                    (actualLevel.id_level === 1 && actualLevel.id_status !== 4)
                  "
                >Não aprovar</v-btn>
                <v-btn
                  class="my-2"
                  color="success"
                  @click="approvalSelect(1)"
                  :loading="loadingApproval"
                  :disabled="disableApprove || actualLevel.id_status === 4"
                >Aprovar</v-btn>
                <v-flex>
                  <v-data-table
                    :headers="headers"
                    :items="showHistory === true ? approvals : levels"
                    :loading="loading"
                    item-key="id_dynamic_report"
                    sort-by="Usuário"
                    class="pb-2"
                    ref="dTable"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.id_status="{ item }">
                      <v-layout>
                        <v-icon
                          class="ml-4"
                          :color="item.id_status === 4 ? 'green' : 'red'"
                        >mdi-circle</v-icon>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
            <div class="light" style="position: sticky; bottom: 0; width: 100%">
              <v-card-actions class="px-6 py-6">
                <v-checkbox
                  v-model="showHistory"
                  label="Exibir o Histórico de todas as Aprovações e Reprovações"
                ></v-checkbox>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              @click="dialogApprovalAll = true"
              :disabled="
                disableActionButtons ||
                purpose.length > 1 ||
                allActualLevelsUnfiltered.length < 1
              "
            >
              <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </template>
          <span>Aprovar múltiplos</span>
        </v-tooltip>

        <v-dialog
          v-model="dialogApprovalAll"
          max-width="900"
          max-height="450"
          style="z-index: 1000"
        >
          <v-card style="position: relative">
            <v-toolbar
              dark
              :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
              style="position: sticky; top: 0; width: 100%; z-index: 5"
              elevation="0"
            >
              <v-toolbar-title>
                <span>Aprovar múltiplos</span>
              </v-toolbar-title>
              <v-spacer/>

              <v-tooltip bottom>
                <template v-slot:activator="on">
                  <v-btn
                    color="transparent"
                    class="mt-2"
                    v-on="on"
                    min-width="48px"
                    @click="closeDialogApprovalAll()"
                    depressed
                  >
                    <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-layout justify-space-between wrap>
                <v-flex xs12>
                  <v-select
                    v-model="selectedEvent"
                    :items="eventOption.items"
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Evento"
                    @change="selectEvent()"
                    return-object
                    persistent-hint
                    readonly
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="selectedPurpose"
                    :items="purposeOption.items"
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    label="Propósito"
                    return-object
                    persistent-hint
                    readonly
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="selectedBP"
                    :items="salesForceItems"
                    :label="'BP'"
                    class="mx-2 my-1"
                    item-text="text"
                    item-value="id"
                    return-object
                    persistent-hint
                    :loading="loadingBPs"
                    @click="salesForceItems.length < 1 ? getBPs() : false"
                    @input="filterActualLevels()"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="justification" class="mx-2 mt-1" label="Justificativa"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-menu top close-on-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card class="mr-1" elevation="0" color="transparent">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="my-2"
                          color="error"
                          :loading="loadingApproval"
                          :disabled="loadingApproval || disableReproveAll"
                          block
                        >
                          Reprovar
                          {{
                          selected.length > 0 ? selected.length : "todos"
                          }}
                          centro(s) de custo(s)
                        </v-btn>
                      </v-card>
                    </template>

                    <v-list>
                      <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                      <v-list-item>
                        <v-layout justify-center>
                          <v-btn @click="approveAll(0)" class="my-2" color="primary" block>Sim</v-btn>
                        </v-layout>
                      </v-list-item>
                      <v-list-item>
                        <v-layout justify-center>
                          <v-btn class="my-2" color="primary" block text>Não</v-btn>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-menu top close-on-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card class="ml-1" elevation="0" color="transparent">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="my-2"
                          color="success"
                          :loading="loadingApproval"
                          :disabled="loadingApproval || disableApproveAll"
                          block
                        >
                          Aprovar
                          {{
                          selected.length > 0 ? selected.length : "todos"
                          }}
                          grupos de planejamento(s)
                        </v-btn>
                      </v-card>
                    </template>

                    <v-list>
                      <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                      <v-list-item>
                        <v-layout justify-center>
                          <v-btn @click="approveAll(1)" class="my-2" color="primary" block>Sim</v-btn>
                        </v-layout>
                      </v-list-item>
                      <v-list-item>
                        <v-layout justify-center>
                          <v-btn class="my-2" color="primary" block text>Não</v-btn>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-data-table
                    v-model="selected"
                    show-select
                    :headers="headersApprovalAll"
                    :items="allActualLevels"
                    :loading="loading"
                    item-key="id"
                    class="pb-2"
                    ref="dTable"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actualLevel="{ item }">
                      <v-layout v-if="item.actualLevel">
                        <v-flex class="mt-5">{{ item.actualLevel.approval_flow_level_description }}</v-flex>
                        <v-spacer></v-spacer>
                        <v-flex>
                          <v-text-field
                            v-model="item.justification"
                            class="mx-2 my-n1"
                            label="Justificativa"
                          ></v-text-field>
                        </v-flex>
                        <v-btn
                          @click="
                            (selectedPurpose = item.id_purpose),
                              (selectedCostCenter = item.id_cost_center),
                              (justification = item.justification),
                              approvalSelect(0)
                          "
                          fab
                          depressed
                          x-small
                          class="error mr-2 mt-3"
                          :loading="loadingApproval"
                          :disabled="
                            loadingApproval ||
                            !item.actualLevel.enable ||
                            (item.actualLevel.id_level === 1 &&
                              item.actualLevel.id_status !== 4)
                          "
                        >
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                          @click="
                            (selectedPurpose = item.id_purpose),
                              (selectedCostCenter = item.id_cost_center),
                              (justification = item.justification),
                              approvalSelect(1)
                          "
                          fab
                          depressed
                          x-small
                          class="success mr-2 mt-3"
                          :loading="loadingApproval"
                          :disabled="
                            loadingApproval ||
                            !item.actualLevel.enable ||
                            item.actualLevel.id_status === 4
                          "
                        >
                          <v-icon small>mdi-check</v-icon>
                        </v-btn>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-dialog>

        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn color="primary" class="mr-4 pa-0"
                    v-on="on"
                    min-width="48px"
                    @click="dialogApprovalAll = true"
                    :disabled="disableActionButtons || purpose.length > 1 || allActualLevelsUnfiltered.length < 1"
                >
                    <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
                </v-btn>
            </template>
            <span>Aprovar múltiplos</span>
        </v-tooltip>

        <v-dialog v-model="dialogApprovalAll" max-width="900" max-height="450" style="z-index: 1000;">
            <v-card style="position: relative;">
                <v-toolbar
                    dark
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                    elevation="0"
                >
                    <v-toolbar-title>
                        <span> Aprovar múltiplos </span>
                    </v-toolbar-title>
                    <v-spacer />

                    <v-tooltip bottom>
                        <template v-slot:activator="on">
                            <v-btn color="transparent" class="mt-2"
                                v-on="on"
                                min-width="48px"
                                @click="closeDialogApprovalAll()"
                                depressed
                            >
                                <v-icon class="white--text">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-container>
                    <v-layout justify-space-between wrap>
                        <v-flex xs12>
                            <v-select
                                v-model="selectedEvent"
                                :items="eventOption.items"
                                class="mx-2 my-1"
                                item-text="text"
                                item-value="id"               
                                label="Evento"
                                @change="selectEvent()"
                                return-object
                                persistent-hint
                                readonly
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                v-model="selectedPurpose"
                                :items="purposeOption.items"
                                class="mx-2 my-1"
                                item-text="text"
                                item-value="id"
                                label="Propósito"
                                return-object
                                persistent-hint
                                readonly
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                v-model="selectedBP"
                                :items="salesForceItems"
                                :label="'BP'"
                                class="mx-2 my-1"
                                item-text="text"
                                item-value="id"
                                return-object
                                persistent-hint
                                :loading="loadingBPs"
                                @click="salesForceItems.length < 1 ? getBPs() : false"
                                @input="filterActualLevels()"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                v-model="justification"
                                class="mx-2 mt-1"
                                label="Justificativa"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6>
                            <v-menu
                                top
                                close-on-click
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-card class="mr-1" elevation="0" color="transparent">
                                        <v-btn v-bind="attrs" v-on="on" class="my-2" color="error" :loading="loadingApproval" :disabled="loadingApproval || disableReproveAll || selectAllToApprove" block>
                                            Reprovar {{ selected.length > 0 ? selected.length : 'todos' }} centro(s) de custo(s)
                                        </v-btn>
                                    </v-card>
                                </template>

                                <v-list>
                                    <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                    <v-list-item>
                                        <v-layout justify-center>
                                            <v-btn @click="approveAll(0)" class="my-2" color="primary" block>
                                                Sim
                                            </v-btn>
                                        </v-layout>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-layout justify-center>
                                            <v-btn class="my-2" color="primary" block text>
                                                Não
                                            </v-btn>
                                        </v-layout>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-checkbox
                                v-model="selectAllToReprove"
                                :disabled="selectAllToApprove"
                                :label="'Selecionar todos liberados para reprovar'"
                                class="mx-2"
                            />
                        </v-flex>
                        <v-flex xs12 sm12 md6>
                            <v-menu
                                top
                                close-on-click
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-card class="ml-1" elevation="0" color="transparent">
                                        <v-btn v-bind="attrs" v-on="on" class="my-2" color="success" :loading="loadingApproval" :disabled="loadingApproval || disableApproveAll || selectAllToReprove" block>
                                            Aprovar {{ selected.length > 0 ? selected.length : 'todos' }} grupos de planejamento(s)
                                        </v-btn>
                                    </v-card>
                                </template>

                                <v-list>
                                    <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                    <v-list-item>
                                        <v-layout justify-center>
                                            <v-btn @click="approveAll(1)" class="my-2" color="primary" block>
                                                Sim
                                            </v-btn>
                                        </v-layout>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-layout justify-center>
                                            <v-btn class="my-2" color="primary" block text>
                                                Não
                                            </v-btn>
                                        </v-layout>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-checkbox
                                v-model="selectAllToApprove"
                                :disabled="selectAllToReprove"
                                :label="'Selecionar todos liberados para aprovar'"
                                class="mx-2"
                            />
                        </v-flex>
                        <v-flex xs12>
                            <v-data-table
                                v-model="selected"
                                show-select
                                :headers="headersApprovalAll"
                                :items="allActualLevels"
                                :loading="loading"
                                item-key="id"
                                class="pb-2"
                                ref="dTable"
                            >
                                <template v-slot:[`header.data-table-select`]></template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:item.actualLevel="{ item }">
                                    <v-layout v-if="item.actualLevel">
                                        <v-flex class=" mt-5">
                                            {{ item.actualLevel.approval_flow_level_description }}
                                        </v-flex>
                                        <v-spacer></v-spacer>
                                        <v-flex>
                                            <v-text-field
                                                v-model="item.justification"
                                                class="mx-2 my-n1"
                                                label="Justificativa"
                                            ></v-text-field>
                                        </v-flex>
                                          <v-btn 
                                              @click="selectedPurpose = item.id_purpose, selectedCostCenter = item.id_cost_center, justification = item.justification, approvalSelect(0)"
                                              fab
                                              depressed
                                              x-small
                                              class="error mr-2 mt-3"
                                              :loading="loadingApproval"
                                              :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_level === 1 && item.actualLevel.id_status !== 4"
                                          >
                                            <v-icon small>
                                              mdi-close
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            @click="selectedPurpose = item.id_purpose, selectedCostCenter = item.id_cost_center, justification = item.justification, approvalSelect(1)"
                                            fab
                                            depressed
                                            x-small 
                                            class="success mr-2 mt-3"
                                            :loading="loadingApproval"
                                            :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_status === 4"
                                        >
                                            <v-icon small>
                                                mdi-check
                                            </v-icon>
                                        </v-btn>
                                    </v-layout>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>
        </v-dialog>

        <v-menu bottom offset-y style="z-index: 1000 !important">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="mr-4 pa-0"
              v-on="on"
              min-width="48px"
              :disabled="disableActionButtons"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>

          <v-list style="overflow-y: auto">
            <v-list-item @click="showFormulas()">
              <v-list-item-content>
                <v-list-item-title>
                  {{
                  isShowingFormulas ? "Ocultar fórmulas" : "Exibir fórmulas"
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="showStyles()">
              <v-list-item-content>
                <v-list-item-title>
                  {{
                  isShowingStyles ? "Remover formatação" : "Exibir formatação"
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <template>
          <v-dialog v-model="dialogCloseGrid" max-width="850px">
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="primary"
                    class="pa-0"
                    v-on="{ ...tooltip, ...dialog }"
                    min-width="48px"
                    @click="closeGrid()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Fechar</span>
              </v-tooltip>
            </template>
            <v-card>
              <div class="d-flex justify-center">
                <v-card-title class="headline">
                  Você possui edições não salvas. Tem certeza de que deseja
                  continuar?
                </v-card-title>
              </div>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                <v-btn
                  color="secondary"
                  text
                  @click="$router.push('/')"
                  class="mr-4"
                >Continuar sem salvar</v-btn>
                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </div>

      <v-divider class="mb-2"></v-divider>

      <v-row
        no-gutters
        class="d-flex justify-start align-center mb-1"
        style="height: 24px !important; width: 100%"
      >
        <v-col class="d-flex justify-start" style="height: 24px !important">
          <v-tabs v-model="tab" height="24px">
            <v-tabs-slider :color="'primary'"></v-tabs-slider>

            <v-tab v-for="item in tabItems" :key="item">
              <span class="text-capitalize">{{ item }}</span>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          v-if="readOnly || isShowingFormulas"
          class="d-flex justify-end"
          style="height: 24px !important; cursor: pointer !important"
        >
          <v-menu offset-y style="z-index: 1000 !important">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex justify-end align-start">
                <div class="d-flex align-center">
                  <v-icon small color="secondary" class="mr-2">mdi-alert-decagram</v-icon>
                  <span class="secondary--text text-caption">Somente consulta</span>
                </div>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <v-list-item dense v-for="(item, i) in readOnlyRules" :key="i" class="ma-0 pa-0">
                  <v-list-item-icon class="mx-0 mr-2 pa-0">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-caption font-weight-regular">{{ item }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row align="center" no-gutters class="pt-3">
            <!-- EVENTO -->
            <v-autocomplete
              name="event"
              :label="eventOption.label"
              :items="
                options.find((option) => option.column === 'id_event').items
              "
              item-value="text"
              return-object
              class="pa-0 mr-4"
              v-model="event"
              @change="selectEvent()"
              @click="backupEvent = event"
              :loading="event ? false : true"
              style="max-width: 200px !important; z-index: 1000 !important"
              :disabled="unsavedChanges"
            />

            <v-dialog v-model="dialogEvent" max-width="850px">
              <v-card>
                <div class="d-flex justify-center">
                  <v-card-title class="headline">
                    Você possui edições não salvas. Tem certeza de que deseja
                    continuar?
                  </v-card-title>
                </div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDialogEvent()" class="mr-4">Cancelar</v-btn>D
                  <v-btn
                    color="secondary"
                    text
                    @click="(editedRows = []), selectEvent()"
                    class="mr-4"
                  >Continuar sem salvar</v-btn>
                  <v-btn color="primary" @click="selectEvent((save = true))">Salvar e continuar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- PROPÓSITO -->
            <v-autocomplete
              name="purpose"
              :label="purposeOption.label"
              :items="
                options.find((option) => option.column === 'id_purpose').items
              "
              item-value="text"
              return-object
              class="pa-0 mr-4"
              v-model="purpose"
              @change="confirmPurposeSelection()"
              @click="backupPurpose = purpose"
              :loading="loadingPurpose"
              style="max-width: 200px !important; z-index: 1000 !important"
              :disabled="unsavedChanges"
            />

            <!-- PROPÓSITO -->
            <!-- <v-autocomplete
                            v-model="purpose"
                            :items="loadingPurpose ? [] : purposeOption.items"
                            :label="purposeOption.label"
                            multiple
                            :loading="loadingPurpose"
                            class="pa-0 mr-4"
                            style="max-width: 200px !important; z-index: 1000 !important;"
                            return-object
                            @blur="confirmPurposeSelection()"
                            :no-data-text="loadingPurpose ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                        >
                            <template v-slot:prepend-item v-if="!loadingPurpose">
                                <template v-if="loadingPurpose">
                                    <v-list-item
                                        :ripple="false"
                                        @mousedown.prevent
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Atualizando dados...
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-else-if="purposeOption.items.length">
                                    <v-list-item
                                        :ripple="false"
                                        @mousedown.prevent
                                        @click="setAllPurposes()"
                                    >
                                        <v-list-item-action>
                                            <v-icon :color="!purpose.length ? 'gray' : 'primary'">
                                                {{ selectAllPurposeIcon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                --- TODOS ---
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </template>

                            <template v-slot:selection="{ item, index }">
                                <span v-if="purpose.length === purposeOption.items.length && index === 0">--- TODOS ---</span>

                                <span v-else-if="purpose.length !== purposeOption.items.length"
                                    style="display: inline-block; max-width: 150px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                >
                                    <span v-if="index === 0" class="mr-1">
                                        {{ item.text }}
                                    </span>

                                    <span v-if="index === 1" class="text-caption primary--text mr-1"
                                        style="max-width: 20px !important;"
                                    >
                                        {{ `+ ${purpose.length - 1}` }}
                                    </span>
                                </span>
                            </template>
            </v-autocomplete>-->

            <v-divider vertical class="mr-4 mb-4"></v-divider>

            <div class="d-flex justify-start">
              <span class="text-h6 mr-4">{{ year1 ? year1.toString() : "Ano 1" }}</span>
              <div class="d-flex mt-1">
                <v-checkbox
                  label="Mês"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year1month"
                  @click="showAllMonths(1)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="260px">
                    <!-- @change="setYear1Months($event)" -->
                    <v-select
                      v-model="year1months"
                      :items="months"
                      label="Escolha os meses"
                      :item-text="(item) => item.text"
                      @change="showMonth(1)"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year1months.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year1month = true), showAllMonths(1)"
                        :disabled="year1months.length === 12"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Tri"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year1quarter"
                  @click="showAllQuarters(1)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="240px">
                    <v-select
                      v-model="year1quarters"
                      :items="quarters"
                      label="Escolha os trimestres"
                      :item-text="(item) => item.text"
                      @change="showQuarter(1)"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year1quarters.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year1quarter = true), showAllQuarters(1)"
                        :disabled="year1quarters.length === 4"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Sem"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year1semester"
                  @click="showAllSemesters(1)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="240px">
                    <v-select
                      v-model="year1semesters"
                      :items="semesters"
                      label="Escolha os semestres"
                      :item-text="(item) => item.text"
                      @change="showSemester(1)"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year1semesters.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year1semester = true), showAllSemesters(1)"
                        :disabled="year1semesters.length === 2"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Ano 1"
                  :ripple="false"
                  class="pa-0 ma-0 mr-4"
                  v-model="startingYear"
                  @click="showYear(1)"
                  :disabled="disableActionButtons"
                />
              </div>
            </div>

            <v-divider v-if="event ? event.id_event_type !== 4 : true" vertical class="mr-4 mb-4"></v-divider>

            <div v-if="event ? event.id_event_type !== 4 : true" class="d-flex justify-start">
              <span class="text-h6 mr-4">{{ year2 ? year2.toString() : "Ano 2" }}</span>
              <div class="d-flex mt-1">
                <v-checkbox
                  label="Mês"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year2month"
                  @click="showAllMonths(2)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="240px">
                    <v-select
                      v-model="year2months"
                      :items="months"
                      :item-text="(item) => item.text"
                      @change="showMonth(2)"
                      label="Escolha os meses"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year2months.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year2month = true), showAllMonths(2)"
                        :disabled="year2months.length === 12"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Tri"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year2quarter"
                  @click="showAllQuarters(2)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="240px">
                    <v-select
                      v-model="year2quarters"
                      :items="quarters"
                      label="Escolha os trimestres"
                      :item-text="(item) => item.text"
                      @change="showQuarter(2)"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year2quarters.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year2quarter = true), showAllQuarters(2)"
                        :disabled="year2quarters.length === 4"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Sem"
                  :ripple="false"
                  class="pa-0 ma-0"
                  v-model="year2semester"
                  @click="showAllSemesters(2)"
                  :disabled="disableActionButtons"
                />

                <v-menu
                  bottom
                  left
                  :close-on-content-click="false"
                  style="z-index: 1000 !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      class="mr-4 pt-1"
                      :disabled="disableActionButtons"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-sheet class="px-4 py-2" max-width="240px">
                    <v-select
                      v-model="year2semesters"
                      :items="semesters"
                      label="Escolha os semestres"
                      :item-text="(item) => item.text"
                      @change="showSemester(2)"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.text }}</span>

                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                        <template v-if="index === 2">
                          <span>...</span>
                          <span
                            class="grey--text text-caption pa-1"
                          >{{ `+ ${year2semesters.length - 2}` }}</span>
                        </template>
                      </template>
                    </v-select>

                    <div class="d-flex justify-end">
                      <v-btn
                        small
                        color="primary"
                        class="ma-0 mb-2"
                        @click="(year2semester = true), showAllSemesters(2)"
                        :disabled="year2semesters.length === 2"
                      >Selecionar todos</v-btn>
                    </div>
                  </v-sheet>
                </v-menu>

                <v-checkbox
                  label="Ano 2"
                  :ripple="false"
                  class="pa-0 ma-0 mr-4"
                  v-model="laterYear"
                  @click="showYear(2)"
                  :disabled="disableActionButtons"
                />
              </div>
            </div>

            <v-divider vertical class="mr-4 mb-4"></v-divider>

            <div class="d-flex mt-1">
              <v-checkbox
                label="Subtotais"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="subtotals"
                :disabled="disableActionButtons"
              />
            </div>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row no-gutters class="pt-3">
            <!-- CENTRO DE CUSTO PRINCIPAL -->
            <v-col @click="setBackupCostCenterMain()">
              <v-autocomplete
                v-model="costCenterMain"
                :items="loadingCostCenterMain ? [] : costCenterMainOption.items"
                :label="costCenterMainOption.label"
                multiple
                :loading="loadingCostCenterMain"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @blur="confirmCostCenterMainSelection()"
                :no-data-text="
                  loadingCostCenterMain
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
                :disabled="!hasMatrixPurpose"
              >
                <template v-slot:prepend-item v-if="!loadingCostCenterMain">
                  <template v-if="loadingCostCenterMain">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="costCenterMainOption.items.length">
                    <v-list-item
                      :ripple="false"
                      @mousedown.prevent
                      @click="setAllCostCenterMains()"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="!costCenterMain.length ? 'gray' : 'primary'"
                        >{{ selectAllCostCenterMainIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      costCenterMain.length ===
                        costCenterMainOption.items.length && index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      costCenterMain.length !==
                      costCenterMainOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${costCenterMain.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- CENTRO DE CUSTO -->
            <v-col @click="setBackupCostCenter()">
              <v-autocomplete
                v-model="costCenter"
                :items="loadingCostCenter ? [] : costCenterOption.items"
                :label="costCenterOption.label"
                multiple
                :loading="loadingCostCenter"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @blur="confirmCostCenterSelection()"
                :no-data-text="
                  loadingCostCenter
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingCostCenter">
                  <template v-if="loadingCostCenter">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="costCenterOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllCostCenters()">
                      <v-list-item-action>
                        <v-icon
                          :color="!costCenter.length ? 'gray' : 'primary'"
                        >{{ selectAllCostCenterIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      costCenter.length === costCenterOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      costCenter.length !== costCenterOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${costCenter.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- COLABORADOR -->
            <v-col @click="setBackupEmployee()">
              <v-autocomplete
                v-model="employee"
                :items="loadingEmployee ? [] : employeeOption.items"
                :label="employeeOption.label"
                multiple
                :loading="loadingEmployee"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @blur="confirmEmployeeSelection()"
                :no-data-text="
                  loadingEmployee
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingEmployee">
                  <template v-if="loadingEmployee">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="employeeOption.items.length">
                    <v-list-item
                      :ripple="false"
                      @mousedown.prevent
                      @click="setAllEmployees('employee')"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="!employee.length ? 'gray' : 'primary'"
                        >{{ selectAllEmployeeIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      employee.length === employeeOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="employee.length !== employeeOption.items.length"
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${employee.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- MATRÍCULA DO COLABORADOR -->
            <v-col>
              <v-autocomplete
                v-model="employeeCd"
                :items="loadingEmployeeCd ? [] : employeeCdOption.items"
                :label="employeeCdOption.label"
                multiple
                :loading="loadingEmployeeCd"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getEmployeeCds()"
                :no-data-text="
                  loadingEmployeeCd
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingEmployeeCd">
                  <template v-if="loadingEmployeeCd">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="employeeCdOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllEmployeeCds()">
                      <v-list-item-action>
                        <v-icon
                          :color="!employeeCd.length ? 'gray' : 'primary'"
                        >{{ selectAllEmployeeCdIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      employeeCd.length === employeeCdOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      employeeCd.length !== employeeCdOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${employeeCd.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- TIPO DE COLABORADOR -->
            <v-col>
              <v-autocomplete
                v-model="employeeType"
                :items="loadingEmployeeType ? [] : employeeTypeOption.items"
                :label="employeeTypeOption.label"
                multiple
                :loading="loadingEmployeeType"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getEmployeeTypes()"
                :no-data-text="
                  loadingEmployeeType
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingEmployeeType">
                  <template v-if="loadingEmployeeType">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="employeeTypeOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllEmployeeTypes()">
                      <v-list-item-action>
                        <v-icon
                          :color="!employeeType.length ? 'gray' : 'primary'"
                        >{{ selectAllEmployeeTypeIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      employeeType.length === employeeTypeOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      employeeType.length !== employeeTypeOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${employeeType.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- DATA DE ADMISSÃO -->
            <v-col>
              <Datepicker
                :filter="admissionDateOption"
                :column="admissionDateOption.column"
                :label="admissionDateOption.label"
                :required="false"
                :disabled="!showAdmissionDate"
                :overwriteClass="'pa-0 mr-4'"
                :overwriteStyle="'min-width: 300px !important; z-index: 1000 !important;'"
                @setDatepickerValue="setDatepickerValue($event)"
              />
            </v-col>

            <!-- DATA DE SAÍDA -->
            <v-col>
              <Datepicker
                :filter="exitDateOption"
                :column="exitDateOption.column"
                :label="exitDateOption.label"
                :required="false"
                :disabled="!showExitDate"
                :overwriteClass="'pa-0 mr-4'"
                :overwriteStyle="'min-width: 300px !important; z-index: 1000 !important;'"
                @setDatepickerValue="setDatepickerValue($event)"
              />
            </v-col>

            <!-- CONTA GERENCIAL -->
            <v-col>
              <v-autocomplete
                v-model="accountGroup"
                :items="loadingAccountGroup ? [] : accountGroupOption.items"
                :label="accountGroupOption.label"
                multiple
                :loading="loadingAccountGroup"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getAccountGroups()"
                :no-data-text="
                  loadingAccountGroup
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingAccountGroup">
                  <template v-if="loadingAccountGroup">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="accountGroupOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllAccountGroups()">
                      <v-list-item-action>
                        <v-icon
                          :color="!accountGroup.length ? 'gray' : 'primary'"
                        >{{ selectAllAccountGroupIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      accountGroup.length === accountGroupOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      accountGroup.length !== accountGroupOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${accountGroup.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- CONTA CONTABIL -->
            <v-col>
              <v-autocomplete
                v-model="chartAccount"
                :items="loadingChartAccount ? [] : chartAccountOption.items"
                :label="chartAccountOption.label"
                multiple
                :loading="loadingChartAccount"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getChartAccounts()"
                :no-data-text="
                  loadingChartAccount
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingChartAccount">
                  <template v-if="loadingChartAccount">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="chartAccountOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllChartAccounts()">
                      <v-list-item-action>
                        <v-icon
                          :color="!chartAccount.length ? 'gray' : 'primary'"
                        >{{ selectAllChartAccountIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      chartAccount.length === chartAccountOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      chartAccount.length !== chartAccountOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${chartAccount.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- DEPARTAMENTO -->
            <v-col>
              <v-autocomplete
                v-model="department"
                :items="loadingDepartment ? [] : departmentOption.items"
                :label="departmentOption.label"
                multiple
                :loading="loadingDepartment"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getDepartments()"
                :no-data-text="
                  loadingDepartment
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingDepartment">
                  <template v-if="loadingDepartment">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="departmentOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllDepartments()">
                      <v-list-item-action>
                        <v-icon
                          :color="!department.length ? 'gray' : 'primary'"
                        >{{ selectAllDepartmentIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      department.length === departmentOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      department.length !== departmentOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${department.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- TIPO DE CONTRATO -->
            <v-col>
              <v-autocomplete
                v-model="salesChannel"
                :items="loadingSalesChannel ? [] : salesChannelOption.items"
                :label="salesChannelOption.label"
                multiple
                :loading="loadingSalesChannel"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getSalesChannels()"
                :no-data-text="
                  loadingSalesChannel
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
                :disabled="!hasMultipleCostCenters"
              >
                <template v-slot:prepend-item v-if="!loadingSalesChannel">
                  <template v-if="loadingSalesChannel">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="salesChannelOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllSalesChannels()">
                      <v-list-item-action>
                        <v-icon
                          :color="!salesChannel.length ? 'gray' : 'primary'"
                        >{{ selectAllSalesChannelIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      salesChannel.length === salesChannelOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      salesChannel.length !== salesChannelOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${salesChannel.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- GERENTE EXECUTIVO -->
            <v-col>
              <v-autocomplete
                v-model="salesForce"
                :items="loadingSalesForce ? [] : salesForceOption.items"
                :label="salesForceOption.label"
                multiple
                :loading="loadingSalesForce"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getSalesForces()"
                :no-data-text="
                  loadingSalesForce
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
                :disabled="!hasMultipleCostCenters"
              >
                <template v-slot:prepend-item v-if="!loadingSalesForce">
                  <template v-if="loadingSalesForce">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="salesForceOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllSalesForces()">
                      <v-list-item-action>
                        <v-icon
                          :color="!salesForce.length ? 'gray' : 'primary'"
                        >{{ selectAllSalesForceIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      salesForce.length === salesForceOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      salesForce.length !== salesForceOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${salesForce.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- BP -->
            <v-col>
              <v-autocomplete
                v-model="salesForceBP"
                :items="loadingSalesForceBP ? [] : salesForceBPOption.items"
                :label="salesForceBPOption.label"
                multiple
                :loading="loadingSalesForceBP"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getSalesForceBPs()"
                :no-data-text="
                  loadingSalesForceBP
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
                :disabled="!hasMultipleCostCenters"
              >
                <template v-slot:prepend-item v-if="!loadingSalesForceBP">
                  <template v-if="loadingSalesForceBP">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="salesForceBPOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllSalesForceBPs()">
                      <v-list-item-action>
                        <v-icon
                          :color="!salesForceBP.length ? 'gray' : 'primary'"
                        >{{ selectAllSalesForceBPIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      salesForceBP.length === salesForceBPOption.items.length &&
                      index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      salesForceBP.length !== salesForceBPOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${salesForceBP.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- CONTA CLIENTE -->
            <v-col>
              <v-autocomplete
                v-model="customerGroup"
                :items="loadingCustomerGroup ? [] : customerGroupOption.items"
                :label="customerGroupOption.label"
                multiple
                :loading="loadingCustomerGroup"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getCustomerGroups()"
                :no-data-text="
                  loadingCustomerGroup
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
                :disabled="!hasMultipleCostCenters"
              >
                <template v-slot:prepend-item v-if="!loadingCustomerGroup">
                  <template v-if="loadingCustomerGroup">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="customerGroupOption.items.length">
                    <v-list-item :ripple="false" @mousedown.prevent @click="setAllCustomerGroups()">
                      <v-list-item-action>
                        <v-icon
                          :color="!customerGroup.length ? 'gray' : 'primary'"
                        >{{ selectAllCustomerGroupIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      customerGroup.length ===
                        customerGroupOption.items.length && index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      customerGroup.length !== customerGroupOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${customerGroup.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- CLASSIFICAÇÃO RH -->
            <v-col>
              <v-autocomplete
                v-model="hrClassification"
                :items="
                  loadingHrClassification ? [] : hrClassificationOption.items
                "
                :label="hrClassificationOption.label"
                multiple
                :loading="loadingHrClassification"
                class="pa-0 mr-4"
                style="min-width: 300px !important; z-index: 1000 !important"
                return-object
                @click="getHrClassifications()"
                :no-data-text="
                  loadingHrClassification
                    ? 'Atualizando dados...'
                    : 'Não há dados disponíveis'
                "
              >
                <template v-slot:prepend-item v-if="!loadingHrClassification">
                  <template v-if="loadingHrClassification">
                    <v-list-item :ripple="false" @mousedown.prevent>
                      <v-list-item-content>
                        <v-list-item-title>Atualizando dados...</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-else-if="hrClassificationOption.items.length">
                    <v-list-item
                      :ripple="false"
                      @mousedown.prevent
                      @click="setAllHrClassifications()"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="!hrClassification.length ? 'gray' : 'primary'"
                        >{{ selectAllHrClassificationIcon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>--- TODOS ---</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      hrClassification.length ===
                        hrClassificationOption.items.length && index === 0
                    "
                  >--- TODOS ---</span>

                  <span
                    v-else-if="
                      hrClassification.length !==
                      hrClassificationOption.items.length
                    "
                    style="
                      display: inline-block;
                      max-width: 250px !important;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                  >
                    <span v-if="index === 0" class="mr-1">{{ item.text }}</span>

                    <span
                      v-if="index === 1"
                      class="text-caption primary--text mr-1"
                      style="max-width: 20px !important"
                    >{{ `+ ${hrClassification.length - 1}` }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
            <v-col
              class="d-flex justify-start"
              cols="2"
              @click="handleSetAllHiddenColumns()"
              style="cursor: pointer"
            >
              <v-icon class="mr-2" :color="setAllHiddenColumnsColor">
                {{
                setAllHiddenColumnsIcon
                }}
              </v-icon>
              <span class="text-subtitle-2">{{ setAllHiddenColumnsText }}</span>
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="purposeOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showPurpose"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="costCenterMainOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showCostCenterMain"
                :disabled="!hasMatrixPurpose"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="costCenterOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showCostCenter"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="employeeOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showEmployee"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="employeeCdOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showEmployeeCd"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="employeeTypeOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showEmployeeType"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="admissionDateOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showAdmissionDate"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="exitDateOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showExitDate"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                label="Conta gerencial"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showAccountGroup"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="chartAccountOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showChartAccount"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="departmentOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showDepartment"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                label="Justificativa da despesa"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showOpexDescription"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="salesChannelOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showSalesChannel"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="salesForceOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showSalesForce"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="salesForceBPOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showSalesForceBP"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="customerGroupOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showCustomerGroup"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>

            <v-col class="d-flex justify-start" cols="2">
              <v-checkbox
                :label="hrClassificationOption.label"
                :ripple="false"
                class="pa-0 ma-0"
                v-model="showHrClassification"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
              <v-checkbox
                label="Remover paginação"
                :ripple="false"
                class="pa-0 ma-0 mr-4"
                v-model="removePagination"
                style="
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
                hide-details
              />

            <v-divider vertical inset></v-divider>

            <span class="ml-4 mr-2">Funcionários por página: </span>
            <v-text-field
              type="number"
              v-model="qtdEmployeesPagination"
              class="centered-input ma-0 pa-0 mr-4"
              hide-details
              style="max-width: 60px !important;"
              :disabled="!showEmployee"
            />

            <span class="ml-4 mr-2">Páginação: </span>
            <v-btn
              icon class="white"
              :disabled="!showEmployee || pageEmployeesPagination <= 0"
            >
              <v-icon color="primary">mdi-chevron-double-left</v-icon>
            </v-btn>

            <v-btn
              icon class="white"
              :disabled="!showEmployee || pageEmployeesPagination <= 0"
              @click="pageEmployeesPagination - 1, loadGrid()"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
            </v-btn>

            <v-text-field
              type="number"
              v-model="pageEmployeesPagination"
              class="centered-input ma-0 pa-0 mx-2"
              hide-details
              style="max-width: 50px !important;"
              :disabled="!showEmployee"
            />

            <v-btn
              icon class="white"
              :disabled="!showEmployee"
              @click="pageEmployeesPagination += 1, loadGrid()"
            >
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>

            <v-btn
              icon class="white"
              :disabled="!showEmployee"
              @click="pageEmployeesPagination + 1, loadGrid()"
            >
              <v-icon color="primary">mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>

    <v-sheet v-if="loading" :height="windowSize.y - 64 - 196" class="rounded-b">
      <div class="d-flex justify-center align-center" style="width: 100%; height: 100%">
        <v-btn large text :loading="loading" color="primary">Carregando</v-btn>
      </div>
    </v-sheet>

    <v-sheet
      v-else-if="!loading && noDataMessage"
      :height="windowSize.y - 64 - 196"
      class="rounded-b"
    >
      <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%">
        <span>{{ noDataMessage }}</span>
        <v-btn
          v-if="noDataBtn.text"
          large
          text
          color="primary"
          class="mt-4"
          @click="handleNoDataBtnCb(noDataBtn)"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          {{ noDataBtn.text }}
        </v-btn>
      </div>
    </v-sheet>

    <v-sheet v-else :height="gridContainerHeight" class="rounded-b px-4">
      <HotTable
        ref="hotTable"
        licenseKey="1c81c-153e1-cb283-a4838-af03f"
        :data="objectData"
        :settings="settings"
        :columns="columns"
        :colHeaders="setColHeaders()"
        :rowHeaders="true"
        :hiddenColumns="hiddenColumns"
        :hiddenRows="hiddenRows"
        :contextMenu="contextMenu"
        :dropdownMenu="dropDownMenu"
        :manualColumnResize="true"
        :multiColumnSorting="true"
        :bindRowsWithHeaders="true"
        :filters="true"
        fixedColumnsLeft="2"
        width="100%"
        height="100%"
        :columnHeaderHeight="50"
        :rowHeaderWidth="50"
        :trimWhitespace="false"
        :copyPaste="true"
        :renderAllRows="false"
      />
    </v-sheet>

    <CommentsMenu
      :drawer="drawer"
      @closeDrawer="drawer = false"
      :parentPayload="{
        id_event: event ? event.id : null,
        id_purpose: purpose ? purpose.id : null,
        id_cost_center: costCenter.length ? costCenter[0].id_cost_center : null,
        id_employee: employee.length ? employee[0].id : null,
      }"
    />

    <v-dialog v-model="dialogSolver" max-width="900px" style="z-index: 5000 !important">
      <v-card>
        <v-card-title class="headline neutral">
          <span class="headline">Atingir meta</span>
        </v-card-title>
        <v-card-text>
          <ZnapSolverDialog :solver="solverProps"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { HotTable } from "@handsontable/vue";
import { registerLanguageDictionary, ptBR } from "handsontable/i18n";
import numbro from "numbro";
import languages from "numbro/dist/languages.min";
import { HyperFormula } from "hyperformula";
import lodash from "lodash";
import moment from "moment";
import CommentsMenu from "@/components/znap/CommentsMenuEmployee.vue";
import ZnapSolverDialog from "@/components/znap/ZnapSolverDialog.vue";
import Datepicker from "@/components/znap/Datepicker.vue";
import configs from "@/configs";
import getModulePort from "@/helpers/getModulePort";

export default {
  name: "Handsontable",

  components: {
    HotTable,
    CommentsMenu,
    ZnapSolverDialog,
    Datepicker
  },

  props: {
    options: { type: Array, required: true }
  },

  data() {
    return {
      tab: 0,
      tabItems: ["Evento", "Filtros", "Exibir", "Opções"],

      // Hot Table
      objectData: [],
      settings: {
        formulas: {
          engine: HyperFormula
        },
        afterSelection: () => {
          if (this.hotInstance) {
            let cellCoords = this.hotInstance.getSelectedLast();

            if (cellCoords) {
              let firstRow = this.hotInstance.toPhysicalRow(cellCoords[0]);
              let lastRow = this.hotInstance.toPhysicalRow(cellCoords[2]);

              if (firstRow === lastRow) {
                this.selectedRowId = firstRow;
              } else {
                this.selectedRowId = [firstRow, lastRow];
              }
            } else {
              this.selectedRowId = null;
            }
          }
        },
        afterChange: changes => {
          if (changes) {
            changes.forEach(c => {
              let row = c[0];
              let col = c[1];
              let newVal = c[2];
              let oldVal = c[3];
              let physicalRow = this.hotInstance.toPhysicalRow(row);

              if (oldVal === "") {
                oldVal = null;
                this.objectData[physicalRow][col] = oldVal;
              }

              if (newVal != oldVal) {
                if (!this.editedRows.includes(physicalRow)) {
                  this.editedRows.push(physicalRow);
                }
              }
            });
          }
        },
        beforeCopy: data => {
          let stringData = data.map(d =>
            d.map(c => String(c).replace(".", ","))
          );
          let excelData = stringData
            .map(lines => lines.join("\t"))
            .join("\t\r\n");

          document.addEventListener("copy", function(e) {
            e.clipboardData.setData("text/plain", excelData);
            e.preventDefault();
          });

          this.$toast.success("Valor copiado para a área de transferência");
          return false;
        },
        language: ptBR.languageCode,
        fillHandle: {
          autoInserRow: false
        },
        cells: (row, col, prop) => {
          var cellProperties = {};

          const stylizedRow = this.stylizedRows[row] || { index: -1 };

          if (row === stylizedRow.index) {
            if (stylizedRow.className) {
              cellProperties.className = stylizedRow.className;
            }

            if (!this.readOnlyColumns.includes(col)) {
              cellProperties.readOnly = !stylizedRow.readOnly ? false : true;
            } else {
              const totalColumns = [
                "year1quarter1",
                "year1quarter2",
                "year1quarter3",
                "year1quarter4",
                "year1semester1",
                "year1semester2",
                "year1",
                "year2quarter1",
                "year2quarter2",
                "year2quarter3",
                "year2quarter4",
                "year2semester1",
                "year2semester2",
                "year2"
              ];

              if (totalColumns.includes(prop)) {
                cellProperties.className += ` border-sides-1 font-bold`;
              } else {
                cellProperties.className += ` read-only-column`;
              }
            }

            if (stylizedRow.percent) {
              cellProperties.numericFormat = {
                pattern: {
                  output: "percent",
                  negative: "parenthesis",
                  mantissa: 2
                },
                culture: ptBR.languageCode
              };
            }
          }

          if (this.isShowingFormulas) {
            cellProperties.readOnly = false;
          } else {
            if (this.readOnly) {
              cellProperties.readOnly = true;
            }
          }

          return cellProperties;
        }
      },
      columns: [],
      colHeaders: [],
      rowHeaders: [],
      stylizedRows: [],
      hiddenColumns: { columns: [], copyPasteEnabled: true },
      hiddenRows: { rows: [], indicators: true },
      contextMenu: [
        "copy",
        "hidden_rows_hide",
        "hidden_rows_show",
        "hidden_columns_hide",
        "hidden_columns_show"
      ],
      dropDownMenu: [
        "filter_by_condition",
        "filter_by_condition2",
        "filter_action_bar",
        "filter_operators",
        "filter_by_value"
      ],

      hotInstance: null,

      dialogSolver: false,
      solverProps: {
        columnName: null,
        column: null,
        row: null,
        rowProps: null,
        targetRow: null,
        targetValue: null,
        changeRow: null
      },

      // ! DADOS DO LOAD

      // Options
      payload: {
        id_event: null,
        id_purpose: null,
        id_cost_center: null,
        id_employee: null,
        id_account_group_version: null
      },

      event: null,
      loadingEvent: true,
      dialogEvent: false,
      backupEvent: null,

      purpose: null,
      loadingPurpose: true,
      showPurpose: false,
      backupPurpose: null,

      costCenterMain: [],
      loadingCostCenterMain: false,
      showCostCenterMain: false,
      backupCostCenterMain: null,

      costCenter: [],
      loadingCostCenter: true,
      showCostCenter: false,
      backupCostCenter: null,

      employee: [],
      loadingEmployee: true,
      showEmployee: false,
      backupEmployee: null,

      employeeCd: [],
      loadingEmployeeCd: false,
      showEmployeeCd: false,

      employeeType: [],
      loadingEmployeeType: false,
      showEmployeeType: false,

      admissionDate: [],
      loadingAdmissionDate: false,
      showAdmissionDate: false,

      exitDate: [],
      loadingExitDate: false,
      showExitDate: false,

      accountGroup: [],
      loadingAccountGroup: false,
      showAccountGroup: true,

      chartAccount: [],
      loadingChartAccount: false,
      showChartAccount: false,

      department: [],
      loadingDepartment: false,
      showDepartment: false,

      showOpexDescription: false,

      salesChannel: [],
      loadingSalesChannel: false,
      showSalesChannel: false,

      salesForce: [],
      loadingSalesForce: false,
      showSalesForce: false,

      salesForceBP: [],
      loadingSalesForceBP: false,
      showSalesForceBP: false,

      customerGroup: [],
      loadingCustomerGroup: false,
      showCustomerGroup: false,

      hrClassification: [],
      loadingHrClassification: false,
      showHrClassification: false,

      subtotals: false,

      disableEdition: false,
      readOnlyRules: [],

      newEmployeeDialog: false,
      selectedNewEmployees: [],
      newEmployeeHeaders: [],
      newEmployeeItems: [],
      loadingEmployeeDialog: false,
      savingNewEmployees: false,
      searchNewEmployee: "",
      filteredNewEmployeeItems: [],
      footerProps: {
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 500, 1000, 5000, -1],
        itemsPerPageAllText: "TODOS"
      },

      // Date filters
      months: [],
      quarters: [],
      semesters: [],
      startingYear: true,
      startingMonthIndex: null,
      year1month: true,
      year1months: [],
      year1quarter: true,
      year1quarters: [],
      year1semester: true,
      year1semesters: [],
      laterYear: true,
      year2month: true,
      year2months: [],
      year2quarter: true,
      year2quarters: [],
      year2semester: true,
      year2semesters: [],
      year1: null,
      year2: null,
      yearMonthInputStart: null,
      fiscalYearStartMonth: null,
      readOnlyColumns: [],
      isShowingFormulas: false,
      isShowingStyles: true,
      backupColHeaders: [],
      backupReadOnly: null,
      disableYear2: false,

      // Unsaved changes
      editedRows: [],
      dialogCloseGrid: false,
      dialogRefreshGrid: false,
      dialogRenewGrid: false,

      windowSize: { x: 0, y: 0 },
      loading: true,
      saving: false,
      noDataMessage: null,
      noDataBtn: { path: null, text: null },

      drawer: false,
      exporting: false,
      importing: false,

      updateMonthsDialog: false,
      loadingUpdateMonths: false,

      dialogReadjustment: false,
      calculatingReadjustment: false,
      dialogReadjustmentEmployee: [],

      selectedRowId: null,
      duplicatingItem: false,

      dialogMassDelete: false,
      deleting: false,

      dialogDuplicateItem: false,
      dialogDuplicateItemAccountGroup: null,
      dialogDuplicateItemChartAccount: null,
      dialogDuplicateItemDescription: "",
      dialogDuplicateItemSelectedRowDescription: null,

      loadingEditableLayout: false,

      qtdEmployeesPagination: 10,
      pageEmployeesPagination: 0,
      removePagination: false,

      // workflow
      readOnly: false,
      readOnlyApproval: false,
      dialogApproval: false,
      dialogApprovalAll: false,
      selected: [],
      loadingApproval: false,
      justification: "",
      headers: [
        {
          text: "Semáforo",
          value: "id_status"
        },
        {
          text: "Nível",
          value: "approval_flow_level_description"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Usuário",
          value: "name"
        },
        {
          text: "Data",
          value: "date"
        },
        {
          text: "Justificativa",
          value: "justification"
        }
      ],
      headersApprovalAll: [
        {
          text: "ID",
          value: "id"
        },
        {
          text: "Centro de custo",
          value: "text"
        },
        {
          text: "Nível",
          value: "actualLevel"
        }
      ],
      levels: [],
      allActualLevels: [],
      allActualLevelsUnfiltered: [],
      selectedPurpose: null,
      costCenters: [],
      selectedCostCenter: null,
      selectedBP: null,
      salesForceItems: [],
      loadingBPs: false,
      selectedEvent: null,
      showHistory: false,
      approvals: null,
      userGroups: [],
      actualLevel: {
        id_level: 0,
        id_group: 0
      },
      disableApprove: true,
      selectAllToApprove: false,
      selectAllToReprove: false,

      dialogCopyValues: false,
      eventRefCopyValues: null,
      purposeRefCopyValues: null,
      copyingValues: false,

      dialogRatio: false,
      loadingRatio: false,
      doRefresh: false,
      headersRatio: [
        {
          text: "ID",
          value: "id"
        },
        {
          text: "Propósito de rateio",
          value: "text"
        }
      ],
      ratios: [],
      ratioMonths: [],
      ratioMonth: null
    };
  },

  mounted() {
    registerLanguageDictionary(ptBR);
    numbro.registerLanguage(languages["pt-BR"]);
    this.addPreventCloseHandler();
  },

  updated() {
    if (this.$refs.hotTable) {
      if (this.hotInstance === null) {
        this.hotInstance = this.$refs.hotTable.hotInstance;
        this.hotInstance.render();
      }
    }
  },

  destroyed() {
    this.removePreventCloseHandler();
  },

  computed: {
    ...mapGetters("auth", ["getHash", "getUser", "getMenu"]),

    tableName() {
      const tableName = this.getTableInfo().tableName;
      return tableName ? tableName : "Erro ao listar a tabela";
    },

    tableIcon() {
      const tableIcon = this.getTableInfo().tableIcon;
      return tableIcon ? tableIcon : "mdi-view-list";
    },

    gridContainerHeight() {
      return this.windowSize.y - 64 - 196;
    },

    disableActionButtons() {
      return (
        this.loadingEvent ||
        this.loadingPurpose ||
        this.loadingCostCenter ||
        this.loadingEmployee ||
        this.loadingAccountGroup ||
        this.loadingChartAccount
      );
    },

    disableSave() {
      return (
        this.disableActionButtons ||
        this.readOnly ||
        !this.unsavedChanges ||
        this.isShowingFormulas
      );
    },

    unsavedChanges() {
      if (this.editedRows.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    disableFirstPrevEmployee() {
      let disable = false;

      if (this.employee === -1 || this.loading || this.showEmployee) {
        disable = true;
      }

      let employees = this.options.find(
        option => option.column === "id_employee"
      ).items;
      if (employees.indexOf(this.employee) === 0) {
        disable = true;
      }

      return disable;
    },

    disableNextLastEmployee() {
      let disable = false;

      if (this.employee === -1 || this.loading || this.showEmployee) {
        disable = true;
      }

      let employees = this.options.find(
        option => option.column === "id_employee"
      ).items;
      if (employees.indexOf(this.employee) === employees.length - 1) {
        disable = true;
      }

      return disable;
    },

    disableDialogCopyValues() {
      let disabled = false;

      if (this.purpose) {
        disabled = !this.isTotalPurpose;
      }

      if (this.readOnly) {
        disabled = true;
      }

      return disabled;
    },

    isTotalPurpose() {
      return this.purpose ? this.purpose.id === 41 : false;
    },

    isBudgetOrActualEvent() {
      return this.event
        ? this.event.id_event_type === 4 || event.id_event_type === 1
        : false;
    },

    isApprovedEvent() {
      return this.event ? this.event.id_status === 4 : false;
    },

    showDuplicateOrDeleteItemBtn() {
      return (
        !this.isShowingFormulas &&
        !this.disableActionButtons &&
        !this.unsavedChanges &&
        this.isTotalPurpose &&
        this.employee.length === 1 &&
        this.showAccountGroup &&
        this.showChartAccount &&
        this.showOpexDescription
      );
    },

    hasMultipleSelection() {
      return (
        this.costCenter.length > 1 ||
        this.employee.length > 1 ||
        this.department.length > 1 ||
        this.salesChannel.length > 1 ||
        this.accountGroup.length > 1 ||
        this.salesForce.length > 1 ||
        this.salesForceBP.length > 1 ||
        this.customerGroup.length > 1 ||
        this.employeeCd.length > 1 ||
        this.employeeType.length > 1 ||
        this.hrClassification.length > 1
      );
    },

    hasMultipleColumns() {
      return (
        this.showPurpose ||
        this.showCostCenter ||
        this.showEmployee ||
        this.showAccountGroup ||
        this.showChartAccount ||
        this.showDepartment ||
        this.showSalesChannel ||
        this.showSalesForce ||
        this.showSalesForceBP ||
        this.showCustomerGroup ||
        this.showEmployeeCd ||
        this.showEmployeeType ||
        this.showAdmissionDate ||
        this.showExitDate ||
        this.showHrClassification
      );
    },

    isNotShowingRequiredColumn() {
      return !this.showChartAccount;
    },

    isNotShowingAnyColumn() {
      return (
        !this.showPurpose &&
        !this.showCostCenterMain &&
        !this.showCostCenter &&
        !this.showEmployee &&
        !this.showAccountGroup &&
        !this.showChartAccount &&
        !this.showDepartment &&
        !this.showOpexDescription &&
        !this.showSalesChannel &&
        !this.showSalesForce &&
        !this.showSalesForceBP &&
        !this.showCustomerGroup &&
        !this.showEmployeeCd &&
        !this.showEmployeeType &&
        !this.showAdmissionDate &&
        !this.showExitDate &&
        !this.showHrClassification
      );
    },

    hasEmptyRequiredFilter() {
      return !this.event || !this.purpose || !this.costCenter.length;
    },

    disableLoadGrid() {
      return false;
    },

    setAllHiddenColumnsText() {
      return this.hasMultipleColumns ? "DESMARCAR TODOS" : "SELECIONAR TODOS";
    },

    setAllHiddenColumnsIcon() {
      if (!this.hasMultipleColumns) {
        return "mdi-checkbox-blank-outline";
      } else {
        return "mdi-close-box";
      }
    },

    setAllHiddenColumnsColor() {
      return this.hasMultipleColumns ? "primary" : "";
    },

    hasMultipleCostCenters() {
      return this.costCenter.length > 1;
    },

    eventOption() {
      return this.options.find(o => o.column === "id_event");
    },

    purposeOption() {
      return this.options.find(o => o.column === "id_purpose");
    },

    costCenterMainOption() {
      return this.options.find(o => o.column === "id_cost_center_main");
    },

    costCenterOption() {
      return this.options.find(o => o.column === "id_cost_center");
    },

    employeeOption() {
      return this.options.find(o => o.column === "id_employee");
    },

    accountGroupOption() {
      return this.options.find(o => o.column === "id_account_group");
    },

    chartAccountOption() {
      return this.options.find(o => o.column === "id_chart_account");
    },

    departmentOption() {
      return this.options.find(o => o.column === "id_department");
    },

    salesChannelOption() {
      return this.options.find(o => o.column === "id_sales_channel");
    },

    salesForceOption() {
      return this.options.find(o => o.column === "id_sales_force");
    },

    salesForceBPOption() {
      return this.options.find(o => o.column === "id_sales_force_bp");
    },

    customerGroupOption() {
      return this.options.find(o => o.column === "id_customer_group");
    },

    employeeCdOption() {
      return this.options.find(o => o.column === "cd_employee");
    },

    employeeTypeOption() {
      return this.options.find(o => o.column === "id_employee_type");
    },

    admissionDateOption() {
      return this.options.find(o => o.column === "admission_date");
    },

    exitDateOption() {
      return this.options.find(o => o.column === "exit_date");
    },

    hrClassificationOption() {
      return this.options.find(o => o.column === "id_hr_classification");
    },

    // selectAllPurposeIcon() {
    //     let icon = ''

    //     if (!this.purpose.length) {
    //         icon = 'mdi-checkbox-blank-outline'
    //     } else if (this.purpose.length < this.purposeOption.items.length) {
    //         icon = 'mdi-minus-box'
    //     } else {
    //         icon = 'mdi-close-box'
    //     }

    //     return icon
    // },

    selectAllCostCenterMainIcon() {
      let icon = "";

      if (!this.costCenterMain.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.costCenterMain.length < this.costCenterMainOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllCostCenterIcon() {
      let icon = "";

      if (!this.costCenter.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (this.costCenter.length < this.costCenterOption.items.length) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllEmployeeIcon() {
      let icon = "";

      if (!this.employee.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (this.employee.length < this.employeeOption.items.length) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllDialogReadjustmentEmployeeIcon() {
      let icon = "";

      if (!this.dialogReadjustmentEmployee.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.dialogReadjustmentEmployee.length <
        this.employeeOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllAccountGroupIcon() {
      let icon = "";

      if (!this.accountGroup.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.accountGroup.length < this.accountGroupOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllChartAccountIcon() {
      let icon = "";

      if (!this.chartAccount.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.chartAccount.length < this.chartAccountOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllDepartmentIcon() {
      let icon = "";

      if (!this.department.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (this.department.length < this.departmentOption.items.length) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllSalesChannelIcon() {
      let icon = "";

      if (!this.salesChannel.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.salesChannel.length < this.salesChannelOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllSalesForceIcon() {
      let icon = "";

      if (!this.salesForce.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (this.salesForce.length < this.salesForceOption.items.length) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllSalesForceBPIcon() {
      let icon = "";

      if (!this.salesForceBP.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.salesForceBP.length < this.salesForceBPOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllCustomerGroupIcon() {
      let icon = "";

      if (!this.customerGroup.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.customerGroup.length < this.customerGroupOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllEmployeeCdIcon() {
      let icon = "";

      if (!this.employeeCd.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (this.employeeCd.length < this.employeeCdOption.items.length) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllEmployeeTypeIcon() {
      let icon = "";

      if (!this.employeeType.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.employeeType.length < this.employeeTypeOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    selectAllHrClassificationIcon() {
      let icon = "";

      if (!this.hrClassification.length) {
        icon = "mdi-checkbox-blank-outline";
      } else if (
        this.hrClassification.length < this.hrClassificationOption.items.length
      ) {
        icon = "mdi-minus-box";
      } else {
        icon = "mdi-close-box";
      }

      return icon;
    },

    hasMatrixPurpose() {
      // return !!this.purpose.filter(i => i.matrix).length
      return this.purpose ? !!this.purpose.matrix : false;
    },

    disableApproveAll() {
      let disable = [];

      if (this.selected.length > 0) {
        if (this.selected.find(level => !level.actualLevel.enable))
          disable.push(true);
        if (this.selected.find(level => level.actualLevel.id_status === 4))
          disable.push(true);
      } else {
        if (this.allActualLevels.find(level => !level.actualLevel.enable))
          disable.push(true);
        if (
          this.allActualLevels.find(level => level.actualLevel.id_status === 4)
        )
          disable.push(true);
      }

      if (this.allActualLevels.length < 1) disable.push(true);

      return disable.includes(true);
    },
    disableReproveAll() {
      let disable = [];

      if (this.selected.length > 0) {
        if (this.selected.find(level => !level.actualLevel.enable))
          disable.push(true);
        if (
          this.selected.find(
            level =>
              level.actualLevel.id_level === 1 &&
              level.actualLevel.id_status !== 4
          )
        )
          disable.push(true);
      } else {
        if (this.allActualLevels.find(level => !level.actualLevel.enable))
          disable.push(true);
        if (
          this.allActualLevels.find(
            level =>
              level.actualLevel.id_level === 1 &&
              level.actualLevel.id_status !== 4
          )
        )
          disable.push(true);
      }
      if (this.allActualLevels.length < 1) disable.push(true);

      return disable.includes(true);
    },

    dialogReadjustmentText() {
      if (this.calculatingReadjustment) {
        return "Calculando reajustes...";
      }

      if (this.unsavedChanges) {
        return "Você possui edições não salvas. Tem certeza de que deseja continuar";
      }

      return "Tem certeza de que deseja continuar?";
    }
  },

  watch: {
    searchNewEmployee(val) {
      if (val) {
        this.filteredNewEmployeeItems = this.newEmployeeItems.filter(i => {
          return (
            i.text
              .toString()
              .toLowerCase()
              .indexOf(val.toString().toLowerCase()) !== -1 ||
            i.id
              .toString()
              .toLowerCase()
              .indexOf(val.toString().toLowerCase()) !== -1 ||
            i.cd_employee
              .toString()
              .toLowerCase()
              .indexOf(val.toString().toLowerCase()) !== -1
          );
        });
      } else {
        this.filteredNewEmployeeItems = [];
      }
    },

    isNotShowingAnyColumn(val) {
      if (val) {
        this.$toast.error('Selecione pelo menos uma coluna da aba "Exibir"');
      }
    },

    selectAllToApprove (val) {
        this.selected = []
        if (val) {
            this.selected = this.allActualLevels.filter(level => level.actualLevel.id_status !== 4 && level.actualLevel.enable)
        }
    },
    selectAllToReprove (val) {
        this.selected = []
        if (val) {
            this.selected = this.allActualLevels.filter(level => level.actualLevel.id_level !== 1 && level.actualLevel.enable || level.actualLevel.id_status === 4 && level.actualLevel.enable)
        }
    },
  },

  async beforeMount() {
    this.init();
    this.loadMonths();
  },

  methods: {
    async init() {
      this.listUserGroups();

      await this.getEvents();
      if (!this.eventOption.items.length) {
        return this.noEventsFound();
      }

      this.setDateColumns();

      await this.getPurposes();
      if (!this.purposeOption.items.length) {
        return this.noPurposesFound();
      }

      if (this.hasMatrixPurpose) {
        await this.getCostCenterMains();
        if (!this.costCenterMainOption.items.length) {
          return this.noCostCenterMainsFound();
        }
      }

      await this.getCostCenters();
      if (!this.costCenterOption.items.length) {
        return this.noCostCentersFound();
      }

      await this.getEmployees();
      if (!this.employeeOption.items.length) {
        return this.noEmployeesFound();
      }

      // await this.approvalListLevels()
      return this.loadGrid();
    },

    async getEvents() {
      this.loadingEvent = true;

      let filter = {
        orderColumn: "description",
        textColumn: "description",
        conditions: [
          {
            AndOr: "AND",
            column: "id_module",
            operator: "=",
            value: 10
          },
          {
            AndOr: "OR",
            column: "id_planning_function",
            operator: "=",
            value: 7
          },
          {
            AndOr: "OR",
            column: "id_planning_function",
            operator: "=",
            value: null
          }
        ]
      };

      try {
        const eventRes = await this.$http.post(
          this.eventOption.endpoint[0] +
            this.eventOption.endpoint[1] +
            "/list-options",
          { filter }
        );
        if (eventRes) {
          this.eventOption.items = eventRes.data.rows;
          let foundFirst = this.eventOption.items.find(
            (item, index) => index === 0
          );
          this.event = foundFirst;
          this.payload.id_event = this.event.id;
          this.payload.id_account_group_version = this.event.id_account_group_version;
          this.year1 = this.event.fiscal_year;
          this.yearMonthInputStart = this.event.year_month_input_start;
          this.fiscalYearStartMonth = this.event.fiscal_year_start_month;
          this.startingMonthIndex = this.event.fiscal_year_start_month - 1;
          this.year2 = this.year1 + 1;

          this.loadingEvent = false;
        }
      } catch (err) {
        this.loadingEvent = false;
        this.$fnError(err);
      }
    },

    closeDialogEvent() {
      this.event = this.backupEvent;
      this.dialogEvent = false;
    },

    async selectEvent(save = false) {
      if (save) {
        const saved = await this.saveData();
        if (saved === "error") return;
      }

      if (this.unsavedChanges && !this.isShowingFormulas) {
        return (this.dialogEvent = true);
      }

      this.loading = true;
      this.dialogEvent = false;

      this.year1 = this.event.fiscal_year;
      this.yearMonthInputStart = this.event.year_month_input_start;
      this.fiscalYearStartMonth = this.event.fiscal_year_start_month;
      this.startingMonthIndex = this.event.fiscal_year_start_month - 1;
      this.year2 = this.year1 + 1;

      this.setDateColumns();

      this.purposeOption.items = [];
      this.purpose = null;

      this.costCenterMainOption.items = [];
      this.costCenterMain = [];

      this.costCenterOption.items = [];
      this.costCenter = [];

      this.employeeOption.items = [];
      this.employee = [];

      this.loadingPurpose = true;
      this.loadingCostCenter = true;
      this.loadingEmployee = true;

      await this.getPurposes();
      if (!this.purposeOption.items.length) {
        return this.noPurposesFound();
      }

      if (this.hasMatrixPurpose) {
        this.loadingCostCenterMain = true;
        await this.getCostCenterMains();
        if (!this.costCenterMainOption.items.length) {
          return this.noCostCenterMainsFound();
        }
      }

      await this.getCostCenters();
      if (!this.costCenterOption.items.length) {
        return this.noCostCentersFound();
      }

      await this.getEmployees();
      if (!this.employeeOption.items.length) {
        return this.noEmployeesFound();
      }

      // await this.approvalListLevels()
      return this.loadGrid();
    },

    noEventsFound() {
      this.event = null;
      this.loadingEvent = false;

      this.purpose = null;
      this.loadingPurpose = false;

      this.costCenterMain = [];
      this.loadingCostCenterMain = false;

      this.costCenter = [];
      this.loadingCostCenter = false;

      this.employee = [];
      this.loadingEmployee = false;

      this.noDataMessage = "Cadastre um evento para iniciar o planejamento";
      this.noDataBtn.path = "/event";
      this.noDataBtn.text = "Cadastrar evento";

      this.loading = false;
    },

    async getPurposes() {
      this.loadingPurpose = true;

      let filter = {
        conditions: [
          {
            AndOr: "AND",
            column: "id_purpose_type",
            operator: "=",
            value: 1 // Despesas
          },
          // {
          //     AndOr: "AND",
          //     column: "matrix",
          //     operator: "=",
          //     value: 0
          // },
          {
            AndOr: "AND",
            column: "id_planning_function",
            operator: "=",
            value: this.event.id_planning_function
          }
        ]
      };

      try {
        const purposeRes = await this.$http.post(
          this.purposeOption.endpoint[0] +
            this.purposeOption.endpoint[1] +
            "/list-options",
          { filter }
        );
        if (purposeRes) {
          this.purposeOption.items = purposeRes.data.rows;

          let foundFirst = this.purposeOption.items.find(
            (item, index) => index === 0
          );
          this.purpose = foundFirst;

          this.loadingPurpose = false;
        }
      } catch (err) {
        this.loadingPurpose = false;
        this.$fnError(err);
      }
    },

    // setBackupPurpose() {
    //     this.backupPurpose = lodash.cloneDeep(this.purpose)
    // },

    async confirmPurposeSelection() {
      if (lodash.isEqual(this.purpose, this.backupPurpose)) {
        return;
      }

      this.loading = true;

      this.costCenterMainOption.items = [];
      this.costCenterMain = [];

      this.costCenterOption.items = [];
      this.costCenter = [];

      this.employeeOption.items = [];
      this.employee = [];

      this.loadingCostCenter = true;
      this.loadingEmployee = true;

      if (this.hasMatrixPurpose) {
        this.loadingCostCenterMain = true;
        await this.getCostCenterMains();
        if (!this.costCenterMainOption.items.length) {
          return this.noCostCenterMainsFound();
        }
      }

      await this.getCostCenters();
      if (!this.costCenterOption.items.length) {
        return this.noCostCentersFound();
      }

      await this.getEmployees();
      if (!this.employeeOption.items.length) {
        return this.noEmployeesFound();
      }

      // await this.approvalListLevels()
      return this.loadGrid();
    },

    // setAllPurposes() {
    //     if (!this.purpose.length) {
    //         this.purpose = lodash.cloneDeep(this.purposeOption.items)
    //     } else {
    //         this.purpose = []
    //     }
    // },

    noPurposesFound() {
      this.purpose = null;
      this.loadingPurpose = false;

      this.costCenter = [];
      this.loadingCostCenter = false;

      this.employee = [];
      this.loadingEmployee = false;

      this.noDataMessage = "Cadastre um propósito para iniciar o planejamento";
      this.noDataBtn.path = "/purpose";
      this.noDataBtn.text = "Cadastrar propósito";

      this.loading = false;
    },

    async getCostCenterMains() {
      this.loadingCostCenterMain = true;

      let filter = { conditions: [] };

      // if (this.purpose.length && this.purpose.length <= this.purposeOption.items.length) {
      //     filter.conditions.push({
      //         AndOr: "AND",
      //         column: "id_purpose",
      //         operator: "IN",
      //         value: this.purpose.map(i => i.id)
      //     })
      // }

      filter.conditions.push({
        AndOr: "AND",
        column: "id_company_group",
        operator: "=",
        value: this.event.id_company_group
      });

      if (this.purpose) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_purpose",
          operator: "IN",
          value: [this.purpose.id]
        });
      }

      try {
        const costCenterMainRes = await this.$http.post(
          this.costCenterMainOption.endpoint[0] +
            this.costCenterMainOption.endpoint[1] +
            "/list-options",
          { filter }
        );
        if (costCenterMainRes) {
          this.costCenterMainOption.items = costCenterMainRes.data.rows;
          this.costCenterMain = lodash.cloneDeep(
            this.costCenterMainOption.items
          );
          this.loadingCostCenterMain = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.loadingCostCenterMain = false;
      }
    },

    setBackupCostCenterMain() {
      this.backupCostCenterMain = lodash.cloneDeep(this.costCenterMain);
    },

    async confirmCostCenterMainSelection() {
      if (lodash.isEqual(this.costCenterMain, this.backupCostCenterMain)) {
        return;
      }

      this.costCenterOption.items = [];
      this.costCenter = [];
      this.loadingCostCenter = true;

      this.employeeOption.items = [];
      this.employee = [];
      this.loadingEmployee = true;

      await this.getCostCenters();
      if (!this.costCenterOption.items.length) {
        return this.noCostCentersFound();
      }

      await this.getEmployees();
      if (!this.employeeOption.items.length) {
        return this.noEmployeesFound();
      }
    },

    setAllCostCenterMains() {
      if (!this.costCenterMain.length) {
        this.costCenterMain = lodash.cloneDeep(this.costCenterMainOption.items);
      } else {
        this.costCenterMain = [];
      }
    },

    noCostCenterMainsFound() {
      this.costCenterMain = [];
      this.loadingCostCenterMain = false;

      this.costCenter = [];
      this.loadingCostCenter = false;

      this.employee = [];
      this.loadingEmployee = false;

      this.noDataMessage =
        "Cadastre um centro de custo principal para iniciar o planejamento";
      this.noDataBtn.path = "/cost-center";
      this.noDataBtn.text = "Cadastrar centro de custo principal";

      return (this.loading = false);
    },

    async getCostCenters() {
      this.loadingCostCenter = true;

      let filter = { conditions: [] };

      // if (this.event.id_event_type === 4) {
      //     endpoint = `${this.$ipOrganization}cost-center/list-options-opex-employee-planning`

      //     filter.conditions.push({
      //         AndOr: "AND",
      //         column: "id_event",
      //         operator: "=",
      //         value: this.event.id
      //     })
      // } else {
      let endpoint = `${this.$ipOrganization}purpose-cost-center/list-options`;

      filter.conditions.push({
        AndOr: "AND",
        column: "id_company_group",
        operator: "=",
        value: this.event.id_company_group
      });

      if (this.purpose) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_purpose",
          operator: "IN",
          value: [this.purpose.id]
        });
      }

      if (
        this.costCenterMain.length &&
        this.costCenterMain.length <= this.costCenterMainOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_cost_center_main",
          operator: "IN",
          value: this.costCenterMain.map(i => i.id_cost_center_main)
        });
      }

      try {
        const costCenterRes = await this.$http.post(endpoint, { filter });
        if (costCenterRes) {
          this.costCenterOption.items = costCenterRes.data.rows;
          this.costCenter = lodash.cloneDeep(this.costCenterOption.items);
          this.loadingCostCenter = false;
        }
      } catch (err) {
        this.loadingCostCenter = false;
        this.$fnError(err);
      }
    },

    setBackupCostCenter() {
      this.backupCostCenter = lodash.cloneDeep(this.costCenter);
    },

    async confirmCostCenterSelection() {
      if (lodash.isEqual(this.costCenter, this.backupCostCenter)) {
        return;
      }

      this.employeeOption.items = [];
      this.employee = [];
      this.loadingEmployee = true;

      await this.getEmployees();
      if (!this.employeeOption.items.length) {
        return this.noEmployeesFound();
      }
    },

    setAllCostCenters() {
      if (!this.costCenter.length) {
        this.costCenter = lodash.cloneDeep(this.costCenterOption.items);
      } else {
        this.costCenter = [];
      }
    },

    noCostCentersFound() {
      this.costCenter = [];
      this.loadingCostCenter = false;

      this.employee = [];
      this.loadingEmployee = false;

      this.accountGroup = [];
      this.loadingAccountGroup = false;

      this.noDataMessage =
        "Cadastre um centro de custo para iniciar o planejamento";
      this.noDataBtn.path = "/cost-center";
      this.noDataBtn.text = "Cadastrar centro de custo";

      return (this.loading = false);
    },

    async getEmployees() {
      this.loadingEmployee = true;

      let endpoint = `${this.$ipOrganization}employee/list-options`;

      let filter = { conditions: [] };

      let year = String(this.event.year_month_input_start).slice(0, 4);
      let month = String(this.event.year_month_input_start).slice(-2);

      filter.conditions.push({
        AndOr: "AND",
        column: "id_company_group",
        operator: "=",
        value: this.event.id_company_group
      });

      filter.conditions.push({
        AndOr: "AND",
        column: "admission_date",
        operator: "<=",
        value: `${this.event.fiscal_year}-12-31`
      });

      filter.conditions.push({
        AndOr: "OR",
        column: "exit_date",
        operator: "IS",
        value: null
      });

      filter.conditions.push({
        AndOr: "OR",
        column: "exit_date",
        operator: ">=",
        value: `${year}-${month}-01`
      });

      try {
        const employeeRes = await this.$http.post(endpoint, { filter });
        if (employeeRes) {
          this.employeeOption.items = employeeRes.data.rows;
          this.employee = lodash.cloneDeep(this.employeeOption.items);
          this.loadingEmployee = false;
        }
      } catch (err) {
        this.loadingEmployee = false;
        this.$fnError(err);
      }
    },

    setBackupEmployee() {
      this.backupEmployee = lodash.cloneDeep(this.employee);
    },

    async confirmEmployeeSelection() {
      if (lodash.isEqual(this.employee, this.backupEmployee)) {
        return;
      }
    },

    setAllEmployees(employeeArray) {
      if (!this[employeeArray].length) {
        this[employeeArray] = lodash.cloneDeep(this.employeeOption.items);
      } else {
        this[employeeArray] = [];
      }
    },

    noEmployeesFound() {
      this.employee = [];
      this.loadingEmployee = false;

      this.accountGroup = [];
      this.loadingAccountGroup = false;

      this.noDataMessage =
        "Cadastre um funcionário para iniciar o planejamento";
      this.noDataBtn.path = "/employee";
      this.noDataBtn.text = "Cadastrar funcionário";

      this.loading = false;
    },

    firstEmployee() {
      this.employee = this.options.find(
        option => option.column === "id_employee"
      ).items[0];
      return this.confirmEmployeeSelection();
    },

    prevEmployee() {
      let currentIndex = this.options
        .find(option => option.column === "id_employee")
        .items.indexOf(this.employee);
      this.employee = this.options.find(
        option => option.column === "id_employee"
      ).items[currentIndex - 1];
      return this.confirmEmployeeSelection();
    },

    nextEmployee() {
      let currentIndex = this.options
        .find(option => option.column === "id_employee")
        .items.indexOf(this.employee);
      this.employee = this.options.find(
        option => option.column === "id_employee"
      ).items[currentIndex + 1];
      return this.confirmEmployeeSelection();
    },

    lastEmployee() {
      let itemsLength = this.options.find(
        option => option.column === "id_employee"
      ).items.length;
      this.employee = this.options.find(
        option => option.column === "id_employee"
      ).items[itemsLength - 1];
      return this.confirmEmployeeSelection();
    },

    async getAccountGroups() {
      this.loadingAccountGroup = true;

      let filter = { conditions: [] };

      // if (this.purpose.length && this.purpose.length <= this.purposeOption.items.length) {
      //     filter.conditions.push({
      //         AndOr: "AND",
      //         column: "id_purpose",
      //         operator: "IN",
      //         value: this.purpose.map(i => i.id)
      //     })
      // }

      if (this.purpose) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_purpose",
          operator: "IN",
          value: [this.purpose.id]
        });
      }

      try {
        const accountGroupRes = await this.$http.post(
          this.accountGroupOption.endpoint[0] +
            this.accountGroupOption.endpoint[1] +
            "/list-options",
          { filter }
        );
        if (accountGroupRes) {
          this.accountGroupOption.items = accountGroupRes.data.rows;
          this.loadingAccountGroup = false;
        }
      } catch (err) {
        this.loadingAccountGroup = false;
        this.$fnError(err);
      }
    },

    setAllAccountGroups() {
      if (!this.accountGroup.length) {
        this.accountGroup = lodash.cloneDeep(this.accountGroupOption.items);
      } else {
        this.accountGroup = [];
      }
    },

    async getChartAccounts() {
      this.loadingChartAccount = true;

      let filter = { conditions: [] };

      if (
        this.accountGroup.length &&
        this.accountGroup.length <= this.accountGroupOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_account_group",
          operator: "IN",
          value: this.accountGroup.map(i => i.id_account_group)
        });
      }

      filter.conditions.push({
        AndOr: "AND",
        column: "id_chart_account_group_version",
        operator: "=",
        value: this.event.id_chart_account_group_version
      });

      try {
        const chartAccountRes = await this.$http.post(
          this.chartAccountOption.endpoint[0] +
            this.chartAccountOption.endpoint[1] +
            "/list-options",
          { filter }
        );
        if (chartAccountRes) {
          this.chartAccountOption.items = chartAccountRes.data.rows;
          this.loadingChartAccount = false;
        }
      } catch (err) {
        this.loadingChartAccount = false;
        this.$fnError(err);
      }
    },

    setAllChartAccounts() {
      if (!this.chartAccount.length) {
        this.chartAccount = lodash.cloneDeep(this.chartAccountOption.items);
      } else {
        this.chartAccount = [];
      }
    },

    async getDepartments() {
      this.loadingDepartment = true;

      let filter = { conditions: [] };

      filter.conditions.push({
        AndOr: "AND",
        column: "id_cost_center_type",
        operator: "=",
        value: 3
      });

      filter.conditions.push({
        AndOr: "AND",
        column: "id_company_group",
        operator: "=",
        value: this.event.id_company_group
      });

      try {
        const departmentRes = await this.$http.post(
          `${this.departmentOption.endpoint[0]}${
            this.departmentOption.endpoint[1]
          }/${
            this.departmentOption.endpoint[2]
              ? this.departmentOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (departmentRes) {
          this.departmentOption.items = departmentRes.data.rows;
          this.loadingDepartment = false;
        }
      } catch (err) {
        this.loadingDepartment = false;
        this.$fnError(err);
      }
    },

    setAllDepartments() {
      if (!this.department.length) {
        this.department = lodash.cloneDeep(this.departmentOption.items);
      } else {
        this.department = [];
      }
    },

    async getSalesChannels() {
      this.loadingSalesChannel = true;

      let filter = { conditions: [] };

      filter.conditions.push({
        AndOr: "AND",
        column: "id_sales_channel_type",
        operator: "=",
        value: 1
      });

      try {
        const salesChannelRes = await this.$http.post(
          `${this.salesChannelOption.endpoint[0]}${
            this.salesChannelOption.endpoint[1]
          }/${
            this.salesChannelOption.endpoint[2]
              ? this.salesChannelOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (salesChannelRes) {
          this.salesChannelOption.items = salesChannelRes.data.rows;
          this.loadingSalesChannel = false;
        }
      } catch (err) {
        this.loadingSalesChannel = false;
        this.$fnError(err);
      }
    },

    setAllSalesChannels() {
      if (!this.salesChannel.length) {
        this.salesChannel = lodash.cloneDeep(this.salesChannelOption.items);
      } else {
        this.salesChannel = [];
      }
    },

    async getSalesForces() {
      this.loadingSalesForce = true;

      let filter = { conditions: [] };

      filter.conditions.push({
        AndOr: "AND",
        column: "id_sales_force_type",
        operator: "=",
        value: 6
      });

      // if (
      //   this.salesChannel.length &&
      //   this.salesChannel.length <= this.salesChannelOption.items.length
      // ) {
      //   filter.conditions.push({
      //     AndOr: "AND",
      //     column: "id_sales_channel",
      //     operator: "IN",
      //     value: this.salesChannel.map((i) => i.id),
      //   });
      // }

      try {
        const salesForceRes = await this.$http.post(
          `${this.salesForceOption.endpoint[0]}${
            this.salesForceOption.endpoint[1]
          }/${
            this.salesForceOption.endpoint[2]
              ? this.salesForceOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (salesForceRes) {
          this.salesForceOption.items = salesForceRes.data.rows;
          this.loadingSalesForce = false;
        }
      } catch (err) {
        this.loadingSalesForce = false;
        this.$fnError(err);
      }
    },

    setAllSalesForces() {
      if (!this.salesForce.length) {
        this.salesForce = lodash.cloneDeep(this.salesForceOption.items);
      } else {
        this.salesForce = [];
      }
    },

    async getSalesForceBPs() {
      this.loadingSalesForceBP = true;

      let filter = { conditions: [] };

      filter.conditions.push({
        AndOr: "AND",
        column: "id_sales_force_type",
        operator: "=",
        value: 7
      });

      try {
        const salesForceBPRes = await this.$http.post(
          `${this.salesForceBPOption.endpoint[0]}${
            this.salesForceBPOption.endpoint[1]
          }/${
            this.salesForceBPOption.endpoint[2]
              ? this.salesForceBPOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (salesForceBPRes) {
          this.salesForceBPOption.items = salesForceBPRes.data.rows;
          this.loadingSalesForceBP = false;
        }
      } catch (err) {
        this.loadingSalesForceBP = false;
        this.$fnError(err);
      }
    },

    setAllSalesForceBPs() {
      if (!this.salesForceBP.length) {
        this.salesForceBP = lodash.cloneDeep(this.salesForceBPOption.items);
      } else {
        this.salesForceBP = [];
      }
    },

    async getCustomerGroups() {
      this.loadingCustomerGroup = true;

      let filter = { conditions: [] };

      filter.conditions.push({
        AndOr: "AND",
        column: "id_customer_group_type",
        operator: "=",
        value: 1
      });

      // if (
      //   this.salesChannel.length &&
      //   this.salesChannel.length <= this.salesChannelOption.items.length
      // ) {
      //   filter.conditions.push({
      //     AndOr: "AND",
      //     column: "id_sales_channel",
      //     operator: "IN",
      //     value: this.salesChannel.map((i) => i.id),
      //   });
      // }

      // if (
      //   this.salesForce.length &&
      //   this.salesForce.length <= this.salesForceOption.items.length
      // ) {
      //   filter.conditions.push({
      //     AndOr: "AND",
      //     column: "id_sales_force",
      //     operator: "IN",
      //     value: this.salesForce.map((i) => i.id),
      //   });
      // }

      try {
        const customerGroupRes = await this.$http.post(
          `${this.customerGroupOption.endpoint[0]}${
            this.customerGroupOption.endpoint[1]
          }/${
            this.customerGroupOption.endpoint[2]
              ? this.customerGroupOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (customerGroupRes) {
          this.customerGroupOption.items = customerGroupRes.data.rows;
          this.loadingCustomerGroup = false;
        }
      } catch (err) {
        this.loadingCustomerGroup = false;
        this.$fnError(err);
      }
    },

    setAllCustomerGroups() {
      if (!this.customerGroup.length) {
        this.customerGroup = lodash.cloneDeep(this.customerGroupOption.items);
      } else {
        this.customerGroup = [];
      }
    },

    async getEmployeeCds() {
      this.loadingEmployeeCd = true;

      let payload = {
        filter: {
          textColumn: "cd_employee",
          orderColumn: "cd_employee",
          conditions: []
        }
      };

      try {
        const employeeCdRes = await this.$http.post(
          `${this.employeeCdOption.endpoint[0]}${
            this.employeeCdOption.endpoint[1]
          }/${
            this.employeeCdOption.endpoint[2]
              ? this.employeeCdOption.endpoint[2]
              : "list-options"
          }`,
          payload
        );
        if (employeeCdRes) {
          this.employeeCdOption.items = employeeCdRes.data.rows;
          this.loadingEmployeeCd = false;
        }
      } catch (err) {
        this.loadingEmployeeCd = false;
        this.$fnError(err);
      }
    },

    setAllEmployeeCds() {
      if (!this.employeeCd.length) {
        this.employeeCd = lodash.cloneDeep(this.employeeCdOption.items);
      } else {
        this.employeeCd = [];
      }
    },

    async getEmployeeTypes() {
      this.loadingEmployeeType = true;

      let filter = { conditions: [] };

      try {
        const employeeTypeRes = await this.$http.post(
          `${this.employeeTypeOption.endpoint[0]}${
            this.employeeTypeOption.endpoint[1]
          }/${
            this.employeeTypeOption.endpoint[2]
              ? this.employeeTypeOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (employeeTypeRes) {
          this.employeeTypeOption.items = employeeTypeRes.data.rows;
          this.loadingEmployeeType = false;
        }
      } catch (err) {
        this.loadingEmployeeType = false;
        this.$fnError(err);
      }
    },

    setAllEmployeeTypes() {
      if (!this.employeeType.length) {
        this.employeeType = lodash.cloneDeep(this.employeeTypeOption.items);
      } else {
        this.employeeType = [];
      }
    },

    setDatepickerValue(e) {
      if (e.column === "admission_date") {
        return (this.admissionDate = e.value);
      }

      if (e.column === "exit_date") {
        return (this.exitDate = e.value);
      }
    },

    async getHrClassifications() {
      this.loadingHrClassification = true;

      let filter = { conditions: [] };

      try {
        const hrClassificationRes = await this.$http.post(
          `${this.hrClassificationOption.endpoint[0]}${
            this.hrClassificationOption.endpoint[1]
          }/${
            this.hrClassificationOption.endpoint[2]
              ? this.hrClassificationOption.endpoint[2]
              : "list-options"
          }`,
          { filter }
        );
        if (hrClassificationRes) {
          this.hrClassificationOption.items = hrClassificationRes.data.rows;
          this.loadingHrClassification = false;
        }
      } catch (err) {
        this.loadingHrClassification = false;
        this.$fnError(err);
      }
    },

    setAllHrClassifications() {
      if (!this.hrClassification.length) {
        this.hrClassification = lodash.cloneDeep(
          this.hrClassificationOption.items
        );
      } else {
        this.hrClassification = [];
      }
    },

    async openNewEmployeeDialog() {
      this.loadingEmployeeDialog = true;
      this.newEmployeeDialog = true;
      this.newEmployeeHeaders = [];
      this.newEmployeeItems = [];
      this.selectedNewEmployees = [];

      let filter = { conditions: [] };

      let year = String(this.event.year_month_input_start).slice(0, 4);
      let month = String(this.event.year_month_input_start).slice(-2);

      filter.conditions.push({
        AndOr: "AND",
        column: "id_company_group",
        operator: "=",
        value: this.event.id_company_group
      });

      filter.conditions.push({
        AndOr: "AND",
        column: "admission_date",
        operator: "<=",
        value: `${this.event.fiscal_year}-12-31`
      });

      filter.conditions.push({
        AndOr: "OR",
        column: "exit_date",
        operator: "IS",
        value: null
      });

      filter.conditions.push({
        AndOr: "OR",
        column: "exit_date",
        operator: ">=",
        value: `${year}-${month}-01`
      });

      const payload = {
        id_event: this.event.id,
        filter
      };

      try {
        const res = await this.$http.post(
          `${
            this.$ipOrganization
          }employee/list-options-not-in-opex-employee-planning`,
          payload
        );
        if (res) {
          this.newEmployeeHeaders = res.data.headers.map(header => {
            return {
              ...header,
              align: header.hideColumn
                ? `${header.align} d-none`
                : header.align,
              width: header.value === "text" ? "" : "1%"
            };
          });
          this.newEmployeeItems = res.data.rows;
          this.loadingEmployeeDialog = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.loadingEmployeeDialog = false;
      }
    },

    async saveNewEmployeeDialog() {
      this.savingNewEmployees = true;

      let payload = {
        items: this.selectedNewEmployees.map(i => {
          return {
            id_event: this.event.id,
            id_purpose: 41, // fixo (RH Folha Total p/ Rateio)
            id_cost_center: 1064, // fixo (Grupo mapa de alocação)
            id_employee: i.id
          };
        })
      };

      try {
        const res = await this.$http.post(
          `${this.$ipOpexPlanning}opex-employee-planning/mass-create`,
          payload
        );
        if (res) {
          this.refresh();
          this.newEmployeeDialog = false;
          this.savingNewEmployees = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.savingNewEmployees = false;
      }
    },

    openReadjustmentDialog() {
      if (!this.isShowingFormulas) {
        this.dialogReadjustmentEmployee = lodash.cloneDeep(this.employee);
        return (this.dialogReadjustment = true);
      }
    },

    async calculateReadjustment(save = false) {
      this.calculatingReadjustment = true;

      if (save) {
        const saved = await this.saveData();
        if (saved === "error") return;
      }

      const employees =
        this.dialogReadjustmentEmployee.length ===
        this.employeeOption.items.length
          ? []
          : this.dialogReadjustmentEmployee.map(i => i.id);

      const payload = {
        id_event: this.event.id,
        employees
      };

      try {
        const res = await this.$http.post(
          `${
            this.$ipOpexPlanning
          }opex-employee-planning/calculate-readjustment`,
          payload
        );
        if (res.data) {
          this.dialogReadjustment = false;
          return this.loadGrid();
        }
      } catch (err) {
        this.calculatingReadjustment = false;
        this.$fnError(err);
      }
    },

    openDialogMassDelete() {
      this.dialogMassDelete = true;
    },

    async massDeleteItemConfirm() {
      this.deleting = true;

      let items = [];
      let payload = {
        delete: {}
      };

      if (this.selectedRowId.length) {
        this.selectedRowId.sort();
        let firstRow = this.selectedRowId[0];
        let lastRow = this.selectedRowId[1];

        for (let i = firstRow; i <= lastRow; i++) {
          items.push({
            id_opex_employee_planning: this.objectData[i].id_opex_employee_planning
          });
        }

        payload.delete = { items };
      } else {
        items.push({
          id_opex_employee_planning: this.objectData[this.selectedRowId].id_opex_employee_planning
        });
        payload.delete = { items };
      }

      try {
        const res = await this.$http.post(
          `${this.$ipOpexPlanning}opex-employee-planning/mass-effect`, payload
        );
        if (res) {
          this.$toast.success(res.data.msg);
          this.loadGrid();
          this.dialogMassDelete = false;
          this.deleting = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.deleting = false;
      }
    },

    async openDialogDuplicateItem() {
      this.dialogDuplicateItem = true;
      this.dialogDuplicateItemAccountGroup = this.objectData[
        this.selectedRowId
      ].account_group_description;
      this.dialogDuplicateItemChartAccount = this.objectData[
        this.selectedRowId
      ].chart_account_description;
      this.dialogDuplicateItemSelectedRowDescription = this.objectData[
        this.selectedRowId
      ].opex_description;
      this.dialogDuplicateItemDescription = "";
    },

    async duplicateItem(save = false) {
      this.duplicatingItem = true;
      if (
        this.dialogDuplicateItemSelectedRowDescription ===
        this.dialogDuplicateItemDescription
      ) {
        this.duplicatingItem = false;
        return this.$toast.error("As descrições precisam ser diferentes");
      }

      if (save) {
        const saved = await this.saveData();
        if (saved === "error") return;
      }

      const selectedRow = {
        ...this.objectData[this.selectedRowId],
        id_opex_employee_planning: null,
        opex_description: this.dialogDuplicateItemDescription
      };

      const payload = {
        items: [selectedRow]
      };

      try {
        const res = await this.$http.post(
          `${this.$ipOpexPlanning}opex-employee-planning/mass-save`, payload
        );
        if (res.data) {
          this.$toast.success(res.data.msg);
          this.loadGrid();
          this.dialogDuplicateItem = false;
          this.duplicatingItem = false;
        }
      } catch (error) {
        this.$fnError(error);
        this.duplicatingItem = false;
      }
    },

    getTableInfo() {
      let tableInfo = {
        tableName: "",
        tableIcon: ""
      };

      for (let i = 0; i < this.getMenu.length; i++) {
        let submenu = this.getMenu[i].menus.find(
          m => m.frontend_route_path === this.$route.path
        );
        if (submenu) {
          tableInfo.tableIcon = this.getMenu[i].icon;
          tableInfo.tableName = submenu.label;
        }
      }

      return tableInfo;
    },

    addPreventCloseHandler() {
      window.addEventListener("beforeunload", this.preventCloseWindow);
    },

    removePreventCloseHandler() {
      window.removeEventListener("beforeunload", this.preventCloseWindow);
    },

    preventCloseWindow(e) {
      if (this.unsavedChanges && !this.isShowingFormulas) {
        e.preventDefault();
        e.returnValue = "";
      }
    },

    async loadGrid() {
      this.loading = true;
      this.editedRows = [];
      this.hotInstance = null;
      this.selectedRowId = null;

      this.noDataMessage = null;
      this.noDataBtn = { path: null, text: null };

      if (this.purpose) {
        this.levels = [];
        this.approvals = [];
        this.selectedEvent = this.event.id;
        this.selectedPurpose = this.purpose.id;
        this.selectedCostCenter = this.costCenter.id_cost_center;
        await this.approvalListLevels();
        await this.approvalHistory();
        await this.setAllLevels();
      }

      if (this.disableLoadGrid) {
        return (this.loading = false);
      }

      this.disableEdition = false;
      this.readOnlyRules = [];
      if (
        this.hasMultipleSelection ||
        this.hasEmptyRequiredFilter ||
        this.isNotShowingRequiredColumn
      ) {
        this.disableEdition = true;
      }

      this.readOnly =
        this.disableEdition || this.readOnlyApproval || this.isShowingFormulas;
      if (this.readOnly) this.setReadOnlyRules();

      let payload = this.setPayload();

      try {
        const opexPlanningRes = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning" + "/list",
          { ...payload }
        );
        // eslint-disable-next-line no-debugger
        //debugger
        if (opexPlanningRes) {
          let _colHeaders = [];
          let _columns = [];
          let resColumns = opexPlanningRes.data.columns;
          this.formulas = opexPlanningRes.data.formulas;

          opexPlanningRes.data.headers.forEach(header => {
            let headerIndex = parseInt(header.value.slice(5, 7), 10);
            let columnIndex = resColumns.findIndex(
              column => column.columnAlias === header.value
            );
            if (columnIndex === -1) return;

            let headerText = header.text;

            if (
              resColumns[columnIndex].columnType === "INT" ||
              resColumns[columnIndex].columnType === "DECIMAL"
            ) {
              if (header.value === "value00") {
                let values = header.text.split(" ");
                headerText = `${values[0]} ${values[1] || ""}`;
              }

              let year = null;
              if (headerIndex > 0 && headerIndex <= 12) {
                if (
                  this.months[headerIndex - 1].id >= this.fiscalYearStartMonth
                ) {
                  year = this.year1;
                } else {
                  year = this.year2;
                }
                headerText = `${this.months[headerIndex - 1].text.slice(
                  0,
                  3
                )}/${year.toString().slice(2, 4)}`;
              } else if (headerIndex >= 13 && headerIndex <= 24) {
                if (
                  this.months[headerIndex - 1 - 12].id >=
                  this.fiscalYearStartMonth
                ) {
                  year = this.year2;
                } else {
                  year = parseInt(this.year2, 10) + 1;
                }
                headerText = `${this.months[headerIndex - 1 - 12].text.slice(
                  0,
                  3
                )}/${year.toString().slice(2, 4)}`;
              }
            }
            _colHeaders.push(headerText);

            let readOnly = false;
            this.readOnly ? (readOnly = true) : (readOnly = false);

            if (header.editable === false) {
              readOnly = true;
            }

            let type = null;
            let className = "";
            let numericFormat = null;
            if (
              resColumns[columnIndex].columnType === "INT" ||
              resColumns[columnIndex].columnType === "DECIMAL"
            ) {
              type = "numeric";
              className += " htRight";
              numericFormat = {
                pattern: {
                  thousandSeparated: true,
                  negative: "parenthesis",
                  mantissa: 2
                },
                culture: ptBR.languageCode
              };
            } else {
              type = "text";
              className += " htLeft";
            }

            let hide = false;
            if (header.hideColumn) {
              hide = true;
            }

            const totalColumns = [
              "year1quarter1",
              "year1quarter2",
              "year1quarter3",
              "year1quarter4",
              "year1semester1",
              "year1semester2",
              "year1",
              "year2quarter1",
              "year2quarter2",
              "year2quarter3",
              "year2quarter4",
              "year2semester1",
              "year2semester2",
              "year2"
            ];

            if (totalColumns.includes(header.value)) {
              className += " border-sides-1 font-bold";
            }

            _columns.push({
              data: header.value,
              readOnly,
              type,
              className,
              numericFormat,
              hide,
              columnLetter: header.columnLetter
            });
          });
          this.colHeaders = _colHeaders;
          this.columns = _columns;

          this.hiddenColumns.columns = this.setHiddenColumns();
          this.setDateReadOnlyColumns();

          let rowHeaders = [];
          let objectData = [];
          this.stylizedRows = [];

          opexPlanningRes.data.rows.forEach((row, index) => {
            rowHeaders.push("");

            let rowProps = {
              index,
              row,
              className: "",
              format: row.format,
              readOnly: false,
              percent: false
            };

            let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
              return cur[1] &&
                typeof cur[1] === "string" &&
                cur[1].includes("Subtotal -")
                ? acc + 1
                : acc + 0;
            }, 0);

            if (subtotalsQty > 1) {
              let counter = 0;
              for (const field in row) {
                if (
                  row[field] &&
                  typeof row[field] === "string" &&
                  row[field].includes("Subtotal -")
                ) {
                  if (counter > 0) {
                    row[field] = "";
                  }

                  counter += 1;
                }
              }
            }

            if (row.indent) {
              rowProps.className += " indentedRow";
              row["account_group_description"] =
                " ".repeat(row.indent) + row["account_group_description"];
            }

            if (row.format.includes("%")) {
              rowProps.percent = true;
            }

            if (row.admission_date) {
              row.admission_date = moment(row.admission_date)
                .utc()
                .format("DD/MM/YYYY");
            }

            if (row.exit_date) {
              row.exit_date = moment(row.exit_date)
                .utc()
                .format("DD/MM/YYYY");
            }

            if (row.css.length) {
              rowProps.className += ` ${row.css}`;
            }

            if (row.subtotal) {
              rowProps.className += ` font-bold`;
            }

            if (row.editable != 1) {
              rowProps.readOnly = true;
            }

            if (row.data_type === "INT" || row.data_type === "DECIMAL") {
              let tot = 24;
              for (let idx = 0; idx <= tot; idx++) {
                if (idx < 10) {
                  if (
                    row[`value0${idx}`] &&
                    row[`value0${idx}`].toString().substr(0, 1) === "="
                  ) {
                    rowProps.readOnly = true;
                    continue;
                  }

                  row[`value0${idx}`] = parseFloat(row[`value0${idx}`]) || null;
                } else {
                  if (
                    row[`value${idx}`] &&
                    row[`value${idx}`].toString().substr(0, 1) === "="
                  ) {
                    rowProps.readOnly = true;
                    continue;
                  }

                  row[`value${idx}`] = parseFloat(row[`value${idx}`]) || null;
                }
              }
            }

            this.stylizedRows.push(rowProps);

            objectData.push(row);
          });
          this.rowHeaders = rowHeaders;
          this.objectData = objectData;

          if (!this.objectData.length) {
            this.noDataMessage = "Não há dados disponíveis";
          }

          this.calculatingReadjustment = false;
          this.loading = false;
        }
      } catch (err) {
        this.calculatingReadjustment = false;
        this.loading = false;
        this.$fnError(err);
      }
    },

    setColHeaders() {
      if (this.isShowingFormulas) {
        return this.colHeaders.map((colHeader, index) => {
          return `${colHeader} (${this.columns[index].columnLetter})`;
        });
      } else {
        return this.colHeaders;
      }
    },

    setPayload() {
      let filter = {
        conditions: []
      };

      if (this.purpose) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_purpose",
          operator: "IN",
          value: [this.purpose.id]
        });
      }

      if (
        this.costCenterMain.length > 0 &&
        this.costCenterMain.length <= this.costCenterMainOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_cost_center_main",
          operator: "IN",
          value: this.costCenterMain.map(i => i.id)
        });
      }

      if (
        this.costCenter.length > 0 &&
        this.costCenter.length <= this.costCenterOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_cost_center",
          operator: "IN",
          value: this.costCenter.map(i => i.id_cost_center)
        });
      }

      if (
        (this.employee.length > 0 &&
          this.employee.length <= this.employeeOption.items.length) ||
        (this.employeeCd.length > 0 &&
          this.employeeCd.length <= this.employeeCdOption.items.length)
      ) {
        let employeeFiltersValues = [];
        let employeeCdFiltersValues = [];

        if (
          this.employee.length > 0 &&
          this.employee.length <= this.employeeOption.items.length
        ) {
          employeeFiltersValues = this.employee.map(i => i.id);
        }

        if (
          this.employeeCd.length > 0 &&
          this.employeeCd.length <= this.employeeCdOption.items.length
        ) {
          employeeCdFiltersValues = this.employeeCd.map(i => i.id);
        }

        filter.conditions.push({
          AndOr: "AND",
          column: "id_employee",
          operator: "IN",
          value: employeeFiltersValues.concat(employeeCdFiltersValues)
        });
      }

      if (
        this.admissionDate !== null &&
        this.admissionDate.value &&
        this.showAdmissionDate
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "admission_date",
          operator: "BETWEEN",
          value: this.admissionDate.value,
          value2: this.admissionDate.value2
            ? this.admissionDate.value2
            : this.admissionDate.value
        });
      }

      if (this.exitDate !== null && this.exitDate.value && this.showExitDate) {
        filter.conditions.push({
          AndOr: "AND",
          column: "exit_date",
          operator: "BETWEEN",
          value: this.exitDate.value,
          value2: this.exitDate.value2
            ? this.exitDate.value2
            : this.exitDate.value
        });
      }

      if (
        this.accountGroup.length > 0 &&
        this.accountGroup.length <= this.accountGroupOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_account_group",
          operator: "IN",
          value: this.accountGroup.map(i => i.id_account_group)
        });
      }

      if (
        this.chartAccount.length > 0 &&
        this.chartAccount.length <= this.chartAccountOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_chart_account",
          operator: "IN",
          value: this.chartAccount.map(i => i.id_chart_account)
        });
      }

      if (
        this.department.length > 0 &&
        this.department.length <= this.departmentOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_department",
          operator: "IN",
          value: this.department.map(i => i.id)
        });
      }

      if (
        this.salesChannel.length > 0 &&
        this.salesChannel.length <= this.salesChannelOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_sales_channel",
          operator: "IN",
          value: this.salesChannel.map(i => i.id)
        });
      }

      if (
        this.salesForce.length > 0 &&
        this.salesForce.length <= this.salesForceOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_sales_force",
          operator: "IN",
          value: this.salesForce.map(i => i.id)
        });
      }

      if (
        this.salesForceBP.length > 0 &&
        this.salesForceBP.length <= this.salesForceBPOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_sales_force_bp",
          operator: "IN",
          value: this.salesForceBP.map(i => i.id)
        });
      }

      if (
        this.customerGroup.length > 0 &&
        this.customerGroup.length <= this.customerGroupOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_customer_group",
          operator: "IN",
          value: this.customerGroup.map(i => i.id)
        });
      }

      if (
        this.employeeType.length > 0 &&
        this.employeeType.length <= this.employeeTypeOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_employee_type",
          operator: "IN",
          value: this.employeeType.map(i => i.id)
        });
      }

      if (
        this.hrClassification.length > 0 &&
        this.hrClassification.length <= this.hrClassificationOption.items.length
      ) {
        filter.conditions.push({
          AndOr: "AND",
          column: "id_hr_classification",
          operator: "IN",
          value: this.hrClassification.map(i => i.id)
        });
      }

      if (!this.removePagination) {
        filter['tableRows'] = this.qtdEmployeesPagination
        filter['page'] = this.pageEmployeesPagination
      }

      let payload = {
        id_event: this.event.id,
        id_account_group_version: this.event.id_account_group_version,
        showPurpose: this.showPurpose,
        showCostCenter: this.showCostCenter,
        showEmployee: this.showEmployee,
        showAccountGroup: this.showAccountGroup,
        showChartAccount: this.showChartAccount,
        showDepartment: this.showDepartment,
        showOpexDescription: this.showOpexDescription,
        showSalesChannel: this.showSalesChannel,
        showSalesForce: this.showSalesForce,
        showSalesForceBp: this.showSalesForceBP,
        showCustomerGroup: this.showCustomerGroup,
        showEmployeeCd: this.showEmployeeCd,
        showEmployeeType: this.showEmployeeType,
        showAdmissionDate: this.showAdmissionDate,
        showExitDate: this.showExitDate,
        showHrClassification: this.showHrClassification,
        removePagination: this.removePagination,
        subtotals: this.subtotals,
        filter
      };

      return payload;
    },

    solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0) {
      function ruleOf3(initValChange, currTargetVal, initValTarget) {
        if (initValTarget === 0) return 0;
        return (initValChange * currTargetVal) / initValTarget;
      }
      // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
      // OK! isso aí, Luc.
      const table = this.hotInstance;

      let initialValueFromTargetRow = parseFloat(
        table.getDataAtCell(targetRow, column)
      );
      let initialValueFromChangeRow = parseFloat(
        table.getDataAtCell(changeRow, column)
      );

      let calculatedChangeRow = ruleOf3(
        initialValueFromChangeRow,
        targetValue,
        initialValueFromTargetRow
      );
      if (!calculatedChangeRow)
        return {
          interations: 0,
          newValueFromChangeRow: initialValueFromChangeRow,
          newValueFromTargetRow: initialValueFromTargetRow
        };

      table.setDataAtCell(changeRow, column, calculatedChangeRow);

      // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

      let condition = true;
      let interations = 0;

      while (condition) {
        interations++;
        let newValueFromTargetRow = parseFloat(
          table.getDataAtCell(targetRow, column)
        );
        let newValueFromChangeRow = parseFloat(
          table.getDataAtCell(changeRow, column)
        );

        let dif = targetValue - newValueFromTargetRow;

        if (Math.abs(dif) <= 0.5) {
          condition = false;
          table.setDataAtCell(changeRow, column, initialValueFromChangeRow);
          return { interations, newValueFromChangeRow, newValueFromTargetRow };
        }
        // TODO: testar o algoritimo e ajustar os incrementos e decrementos
        if (dif > 0 && dif <= 1)
          newValueFromChangeRow = newValueFromChangeRow + 0.1;
        if (dif > 1) newValueFromChangeRow = newValueFromChangeRow + 1;
        if (dif < 0 && dif >= -1)
          newValueFromChangeRow = newValueFromChangeRow - 0.1;
        if (dif < -1) newValueFromChangeRow = newValueFromChangeRow - 1;

        table.setDataAtCell(changeRow, column, newValueFromChangeRow);
        // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

        if (interations > 10000) condition = false;
      }
      return {
        interations,
        newValueFromChangeRow: initialValueFromChangeRow,
        newValueFromTargetRow: initialValueFromTargetRow
      };
    },

    setDateColumns() {
      this.startingYear = true;
      this.year1month = true;
      this.year1quarter = false;
      this.year1semester = false;
      this.laterYear = false;
      this.year2month = false;
      this.year2quarter = false;
      this.year2semester = false;

      this.months = this.$calendar.months();
      this.quarters = this.$calendar.quarters();
      this.semesters = this.$calendar.semesters();
      let _months = [];
      this.months.forEach(month => {
        if (month.id - 1 < this.startingMonthIndex) {
          _months.splice(this.startingMonthIndex + month.id - 1, 0, month);
        } else {
          _months.splice(month.id - 1 - this.startingMonthIndex, 0, month);
        }
      });
      this.months = _months;
      this.year1months = this.months;
      // this.year2months = this.months;
      // this.year1quarters = this.quarters;
      // this.year2quarters = this.quarters;
      // this.year1semesters = this.semesters;
      // this.year2semesters = this.semesters;
    },

    handleSetAllHiddenColumns() {
      if (this.hasMultipleColumns) {
        this.showPurpose = false;
        this.showCostCenterMain = false;
        this.showCostCenter = false;
        this.showEmployee = false;
        this.showAccountGroup = false;
        this.showChartAccount = false;
        this.showDepartment = false;
        this.showOpexDescription = false;
        this.showSalesChannel = false;
        this.showSalesForce = false;
        this.showSalesForceBP = false;
        this.showCustomerGroup = false;
        this.showEmployeeType = false;
        this.showEmployeeCd = false;
        this.showAdmissionDate = false;
        this.showExitDate = false;
        this.showHrClassification = false;
      } else {
        this.showPurpose = true;
        this.showCostCenterMain = true;
        this.showCostCenter = true;
        this.showEmployee = true;
        this.showAccountGroup = true;
        this.showChartAccount = true;
        this.showDepartment = true;
        this.showOpexDescription = true;
        this.showSalesChannel = true;
        this.showSalesForce = true;
        this.showSalesForceBP = true;
        this.showCustomerGroup = true;
        this.showEmployeeType = true;
        this.showEmployeeCd = true;
        this.showAdmissionDate = true;
        this.showExitDate = true;
        this.showHrClassification = true;
      }
    },

    setHiddenColumns() {
      let cols = [];

      this.columns.forEach((c, i) => {
        if (c.hide) {
          cols.push(i);
        }
      });

      for (let i = 1; i <= 12; i++) {
        let col = null;
        let foundMonth = this.year1months.find(m => m.id === i);

        if (!foundMonth) {
          if (i < 10) {
            col = this.columns.findIndex(c => c.data === `value0${i}`);
          } else {
            col = this.columns.findIndex(c => c.data === `value${i}`);
          }
        }

        if (col) {
          cols.push(col);
        }
      }

      if (this.event.id_event_type !== 4) {
        for (let i = 13; i <= 24; i++) {
          let col = null;
          let foundMonth = this.year2months.find(m => m.id === i - 12);

          if (!foundMonth) {
            col = this.columns.findIndex(c => c.data === `value${i}`);
          }

          if (col) {
            cols.push(col);
          }
        }
      }

      for (let i = 1; i <= 4; i++) {
        let col = null;
        let foundQuarter = this.year1quarters.find(q => q.id === i);

        if (!foundQuarter) {
          col = this.columns.findIndex(c => c.data === `year1quarter${i}`);
        }

        if (col) {
          cols.push(col);
        }
      }

      if (this.event.id_event_type !== 4) {
        for (let i = 1; i <= 4; i++) {
          let col = null;
          let foundQuarter = this.year2quarters.find(q => q.id === i);

          if (!foundQuarter) {
            col = this.columns.findIndex(c => c.data === `year2quarter${i}`);
          }

          if (col) {
            cols.push(col);
          }
        }
      }

      for (let i = 1; i <= 2; i++) {
        let col = null;
        let foundSemester = this.year1semesters.find(s => s.id === i);

        if (!foundSemester) {
          col = this.columns.findIndex(c => c.data === `year1semester${i}`);
        }

        if (col) {
          cols.push(col);
        }
      }

      if (this.event.id_event_type !== 4) {
        for (let i = 1; i <= 2; i++) {
          let col = null;
          let foundSemester = this.year2semesters.find(s => s.id === i);

          if (!foundSemester) {
            col = this.columns.findIndex(c => c.data === `year2semester${i}`);
          }

          if (col) {
            cols.push(col);
          }
        }
      }

      for (let i = 1; i <= 2; i++) {
        let col = null;

        if (i === 1) {
          if (!this.startingYear) {
            col = this.columns.findIndex(c => c.data === `year${i}`);
          }
        } else if (i === 2 && this.event.id_event_type !== 4) {
          if (!this.laterYear) {
            col = this.columns.findIndex(c => c.data === `year${i}`);
          }
        }

        if (col) {
          cols.push(col);
        }
      }

      return cols;
    },

    setDateReadOnlyColumns() {
      const monthInputStart = parseInt(
        this.yearMonthInputStart.toString().slice(4, 6)
      );
      const difference = monthInputStart - this.fiscalYearStartMonth;

      this.readOnlyColumns = [];

      // verify if month index is before company fiscal year
      if (difference > 0) {
        for (let i = 1; i <= difference; i++) {
          let col = null;

          if (i < 10) {
            col = `value0${i}`;
          } else {
            col = `value${i}`;
          }

          let foundColumn = this.columns.find(c => c.data === col);
          let foundColumnIndex = this.columns.findIndex(c => c.data === col);
          if (foundColumn) {
            foundColumn.readOnly = true;
            foundColumn.className += " read-only-column";
            this.readOnlyColumns.push(foundColumnIndex);
          }
        }
      }

      const totalColumns = [
        "year1quarter1",
        "year1quarter2",
        "year1quarter3",
        "year1quarter4",
        "year1semester1",
        "year1semester2",
        "year1",
        "year2quarter1",
        "year2quarter2",
        "year2quarter3",
        "year2quarter4",
        "year2semester1",
        "year2semester2",
        "year2"
      ];

      // verify if column is readOnly
      this.columns.forEach((c, i) => {
        if (totalColumns.includes(c.data) && c.readOnly) {
          this.readOnlyColumns.push(i);
        }
      });
    },

    showAllMonths(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let cols = [];
      let col = null;

      if (year === 1) {
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            col = this.hotInstance.propToCol(`value0${i}`);
          } else {
            col = this.hotInstance.propToCol(`value${i}`);
          }
          cols.push(col);
        }

        if (this.year1month) {
          this.year1months = this.months;
          plugin.showColumns(cols);
        } else {
          this.year1months = [];
          plugin.hideColumns(cols);
        }
      } else if (year === 2) {
        for (let i = 13; i <= 24; i++) {
          col = this.hotInstance.propToCol(`value${i}`);
          cols.push(col);
        }

        if (this.year2month) {
          this.year2months = this.months;
          plugin.showColumns(cols);
        } else {
          this.year2months = [];
          plugin.hideColumns(cols);
        }
      }

      this.hotInstance.render();
    },

    showMonth(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let col = null;

      if (year === 1) {
        for (let i = 1; i <= 12; i++) {
          let index = i + this.startingMonthIndex;
          if (index > 12) index -= 12;
          let foundMonth = this.year1months.find(month => month.id === index);
          if (i < 10) {
            col = this.hotInstance.propToCol(`value0${i}`);
          } else {
            col = this.hotInstance.propToCol(`value${i}`);
          }

          if (foundMonth) {
            plugin.showColumn(col);
          } else {
            plugin.hideColumn(col);
          }
        }
      } else if (year === 2) {
        for (let i = 13; i <= 24; i++) {
          let index = i - 12 + this.startingMonthIndex;
          if (index > 12) index -= 12;
          let foundMonth = this.year2months.find(month => month.id === index);
          col = this.hotInstance.propToCol(`value${i}`);

          if (foundMonth) {
            plugin.showColumn(col);
          } else {
            plugin.hideColumn(col);
          }
        }
      }

      this.year1months.length
        ? (this.year1month = true)
        : (this.year1month = false);
      this.year2months.length
        ? (this.year2month = true)
        : (this.year2month = false);

      this.hotInstance.render();
    },

    showAllQuarters(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let cols = [];
      let col = null;

      for (let i = 1; i <= 4; i++) {
        col = this.hotInstance.propToCol(`year${year}quarter${i}`);
        cols.push(col);
      }

      if (year === 1) {
        if (this.year1quarter) {
          this.year1quarters = this.quarters;
          plugin.showColumns(cols);
        } else {
          this.year1quarters = [];
          plugin.hideColumns(cols);
        }
      } else if (year === 2) {
        if (this.year2quarter) {
          this.year2quarters = this.quarters;
          plugin.showColumns(cols);
        } else {
          this.year2quarters = [];
          plugin.hideColumns(cols);
        }
      }

      this.hotInstance.render();
    },

    showQuarter(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let array = null;
      let col = null;

      if (year === 1) {
        array = this.year1quarters;
      } else if (year === 2) {
        array = this.year2quarters;
      }

      for (let i = 1; i <= 4; i++) {
        let foundQuarter = array.find(quarter => quarter.id === i);
        col = this.hotInstance.propToCol(`year${year}quarter${i}`);
        if (foundQuarter) {
          plugin.showColumn(col);
        } else {
          plugin.hideColumn(col);
        }
      }

      this.year1quarters.length
        ? (this.year1quarter = true)
        : (this.year1quarter = false);
      this.year2quarters.length
        ? (this.year2quarter = true)
        : (this.year2quarter = false);

      this.hotInstance.render();
    },

    showAllSemesters(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let cols = [];
      let col = null;

      for (let i = 1; i <= 2; i++) {
        col = this.hotInstance.propToCol(`year${year}semester${i}`);
        cols.push(col);
      }

      if (year === 1) {
        if (this.year1semester) {
          this.year1semesters = this.semesters;
          plugin.showColumns(cols);
        } else {
          this.year1semesters = [];
          plugin.hideColumns(cols);
        }
      } else if (year === 2) {
        if (this.year2semester) {
          this.year2semesters = this.semesters;
          plugin.showColumns(cols);
        } else {
          this.year2semesters = [];
          plugin.hideColumns(cols);
        }
      }

      this.hotInstance.render();
    },

    showSemester(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let array = null;
      let col = null;

      if (year === 1) {
        array = this.year1semesters;
      } else if (year === 2) {
        array = this.year2semesters;
      }

      for (let i = 1; i <= 2; i++) {
        let foundSemester = array.find(semester => semester.id === i);
        col = this.hotInstance.propToCol(`year${year}semester${i}`);
        if (foundSemester) {
          plugin.showColumn(col);
        } else {
          plugin.hideColumn(col);
        }
      }

      this.year1semesters.length
        ? (this.year1semester = true)
        : (this.year1semester = false);
      this.year2semesters.length
        ? (this.year2semester = true)
        : (this.year2semester = false);

      this.hotInstance.render();
    },

    showYear(year) {
      let plugin = this.hotInstance.getPlugin("hiddenColumns");
      let col = this.hotInstance.propToCol(`year${year}`);

      if (year === 1) {
        if (this.startingYear) {
          plugin.showColumn(col);
        } else {
          plugin.hideColumn(col);
        }
      } else if (year === 2) {
        if (this.laterYear) {
          plugin.showColumn(col);
        } else {
          plugin.hideColumn(col);
        }
      }

      this.hotInstance.render();
    },

    showFormulas() {
      this.isShowingFormulas = !this.isShowingFormulas;

      if (this.isShowingFormulas) {
        this.backupColHeaders = lodash.cloneDeep(this.colHeaders);
        this.backupReadOnly = this.readOnly;
        this.colHeaders = true;
      } else {
        this.colHeaders = this.backupColHeaders;
        this.readOnly = this.backupReadOnly;
      }

      return this.loadGrid();
    },

    showStyles() {
      this.isShowingStyles = !this.isShowingStyles;

      return this.loadGrid();
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    async saveData() {
      this.saving = true;

      let payload = { items: [] };

      for (let i = 0; i < this.objectData.length; i++) {
        // if (this.editedRows.includes(i)) {
        let object = {};

        for (const key in this.objectData[i]) {
          object[key] = this.hotInstance.getDataAtRowProp(i, key);
        }

        payload.items.push(object);
        // }
      }

      try {
        const res = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning" + "/mass-save",
          { ...payload }
        );
        if (res) {
          this.editedRows = [];
          this.$toast.success(res.data.msg);
          this.saving = false;
          this.loadGrid();
          return "success";
        }
      } catch (err) {
        this.saving = false;
        this.$fnError(err);
        return "error";
      }
    },

    async renew(save = false) {
      if (save) {
        const saved = await this.saveData();
        if (saved === "error") return;
      }

      if (this.unsavedChanges && !this.isShowingFormulas) {
        return (this.dialogRenewGrid = true);
      }

      this.loading = true;

      this.dialogRenewGrid = false;
      this.hotInstance = null;

      this.eventOption.items = [];
      this.event = [];

      this.purposeOption.items = [];
      this.purpose = null;

      this.costCenterOption.items = [];
      this.costCenter = [];

      this.employeeOption.items = [];
      this.employee = [];

      this.accountGroupOption.items = [];
      this.accountGroup = [];

      this.chartAccountOption.items = [];
      this.chartAccount = [];

      this.departmentOption.items = [];
      this.department = [];

      this.salesChannelOption.items = [];
      this.salesChannel = [];

      this.salesForceOption.items = [];
      this.salesForce = [];

      this.salesForceBPOption.items = [];
      this.salesForceBP = [];

      this.customerGroupOption.items = [];
      this.customerGroup = [];

      this.employeeCdOption.items = [];
      this.employeeCd = [];

      this.employeeTypeOption.items = [];
      this.employeeType = [];

      this.hrClassificationOption.items = [];
      this.hrClassification = [];

      this.showPurpose = false;
      this.showCostCenter = false;
      this.showChartAccount = false;
      this.showAccountGroup = false;
      this.showDepartment = false;
      this.showSalesChannel = false;
      this.showSalesForce = false;
      this.showSalesForceBP = false;
      this.showCustomerGroup = false;
      this.showEmployeeType = false;
      this.showEmployeeCd = false;
      this.showAdmissionDate = false;
      this.showExitDate = false;
      this.showHrClassification = false;

      this.loadingEvent = true;
      this.loadingPurpose = true;
      this.loadingCostCenter = true;
      this.loadingEmployee = true;
      this.loadingAccountGroup = true;

      this.subtotals = true;

      return this.init();
    },

    async refresh(save = false) {
      if (save) {
        const saved = await this.saveData();
        if (saved === "error") return;
      }

      if (this.unsavedChanges && !this.isShowingFormulas) {
        return (this.dialogRefreshGrid = true);
      }

      this.loading = true;
      this.dialogRefreshGrid = false;
      this.hotInstance = null;
      this.loadGrid();
    },

    async enableEditableLayout() {
      this.loading = true;
      this.loadingEditableLayout = true;

      this.showPurpose = true;
      this.showCostCenter = true;
      this.showEmployee = true;
      this.showChartAccount = true;

      this.purpose = [this.purposeOption.items[0]];
      await this.confirmPurposeSelection();
      this.employee = [this.employeeOption.items[0]];
      await this.confirmEmployeeSelection();

      this.loadingEditableLayout = false;
      this.loadGrid();
    },

    closeGrid() {
      if (!this.unsavedChanges) {
        this.$router.push("/");
      }
    },

    setReadOnlyRules() {
      if (this.isShowingFormulas) {
        this.readOnlyRules.push("Exibindo fórmulas");
      }

      if (this.isTotalPurpose) {
        if (!this.costCenterMain.length) {
          this.readOnlyRules.push("Centro de custo principal é obrigatório");
        }

        if (this.costCenterMain.length > 1) {
          this.readOnlyRules.push(
            "Selecione apenas um centro de custo principal"
          );
        }
      }

      if (!this.costCenter.length) {
        this.readOnlyRules.push("Centro de custo é obrigatório");
      }

      if (this.costCenter.length > 1) {
        this.readOnlyRules.push("Selecione apenas um centro de custo");
      }

      if (!this.employee.length) {
        this.readOnlyRules.push("Colaborador é obrigatório");
      }

      if (this.employee.length > 1) {
        this.readOnlyRules.push("Selecione apenas um colaborador");
      }

      if (!this.showChartAccount) {
        this.readOnlyRules.push("Exiba a coluna de Conta contábil");
      }

      if (this.readOnlyApproval) {
        if (
          this.actualLevel &&
          this.actualLevel.id_level > 0 &&
          this.actualLevel.id_status !== 4
        ) {
          this.readOnlyRules.push("Nível de aprovação não permite alterações");
        } else {
          this.readOnlyRules.push("Evento com todos os níveis aprovados");
        }
      }
    },

    handleNoDataBtnCb(noDataBtn) {
      if (noDataBtn.path === "/employee") {
        let url = window.location.href;
        let path = null;
        if (url.includes(":80")) {
          let port = getModulePort("masterdata");
          path = `http://${configs.system}.masterdata.znaptech:${port}/?token=${
            this.getHash
          }&route=employee`;
        } else {
          path = `https://${configs.system}.masterdata.znaptech.com/?token=${
            this.getHash
          }&route=employee`;
        }

        return window.open(path);
      } else {
        return this.$router.push(noDataBtn.path);
      }
    },

    async exportSheet() {
      this.exporting = true;

      let payload = this.setPayload();
      payload["columnsToRemove"] = this.setColumnsToRemove();
      payload["showEmployee"] = this.showEmployee;

      try {
        const res = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning/report",
          { ...payload }
        );
        if (res) {
          window.open(res.data.token);
          this.$toast.success(res.data.msg);
          this.exporting = false;
        }
      } catch (err) {
        this.exporting = false;
        this.$fnError(err);
      }
    },

    setColumnsToRemove() {
      let columnsToRemove = [];

      // year 1 months
      for (let i = 1; i <= 12; i++) {
        let foundMonth = this.year1months.find(m => m.id === i);

        let index = i < 10 ? `0${i}` : i;

        if (!foundMonth) {
          columnsToRemove.push(`value${index}`);
        }
      }

      // year 2 months
      for (let i = 13; i <= 24; i++) {
        let foundMonth = this.year2months.find(m => m.id === i - 12);

        if (!foundMonth) {
          columnsToRemove.push(`value${i}`);
        }
      }

      // year 1 quarters
      for (let i = 1; i <= 4; i++) {
        let foundQuarter = this.year1quarters.find(q => q.id === i);

        if (!foundQuarter) {
          columnsToRemove.push(`year1quarter${i}`);
        }
      }

      // year 2 quarters
      for (let i = 1; i <= 4; i++) {
        let foundQuarter = this.year2quarters.find(q => q.id === i);

        if (!foundQuarter) {
          columnsToRemove.push(`year2quarter${i}`);
        }
      }

      // year 1 semesters
      for (let i = 1; i <= 2; i++) {
        let foundSemester = this.year1semesters.find(s => s.id === i);

        if (!foundSemester) {
          columnsToRemove.push(`year1semester${i}`);
        }
      }

      // year 2 semesters
      for (let i = 1; i <= 2; i++) {
        let foundSemester = this.year2semesters.find(s => s.id === i);

        if (!foundSemester) {
          columnsToRemove.push(`year2semester${i}`);
        }
      }

      // years
      if (!this.startingYear) {
        columnsToRemove.push(`year1`);
      }

      if (!this.laterYear) {
        columnsToRemove.push(`year2`);
      }

      return columnsToRemove;
    },

    importSheet() {
      this.$refs.uploader.click();
    },

    async onFileChanged(e) {
      if (e) {
        this.importing = true;
        let sheet = e.target.files[0];
        let body = new FormData();
        body.append("spreadsheet", sheet);
        body.append("showEmployee", this.showEmployee);

        try {
          const uploadRes = await this.$http.post(
            this.$ipOpexPlanning +
              "opex-employee-planning" +
              "/upload-spreadsheet",
            body,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          if (uploadRes) {
            // const massEffectRes = await this.$http.post(this.$ipOpexPlanning + 'opex-employee-planning' + '/mass-effect', { ...uploadRes.data.id })
            // if (massEffectRes) {
            this.$toast.success(uploadRes.data.msg);
            this.$refs.uploader.value = null;
            this.importing = false;
            this.refresh();
            // }
          }
        } catch (err) {
          this.$refs.uploader.value = null;
          this.importing = false;
          this.$fnError(err);
        }
      }
    },

    async copyValues() {
      this.copyingValues = true;

      let payload = {
        id_event: this.event.id,
        id_event_reference: this.eventRefCopyValues.id,
        id_purpose: 41,
        id_purpose_reference: this.purposeRefCopyValues.id
      };

      try {
        const res = await this.$http.post(
          `${this.$ipOpexPlanning}opex-employee-planning/copy-data`,
          payload
        );
        if (res) {
          this.$toast.success(res.data.msg);
          this.copyingValues = false;
          this.closeDialogCopyValues();
          return this.refresh();
        }
      } catch (err) {
        this.copyingValues = false;
        this.$fnError(err);
      }
    },

    closeDialogCopyValues() {
      this.eventRefCopyValues = null;
      this.purposeRefCopyValues = null;
      this.dialogCopyValues = false;
    },

    async approvalSelect(option) {
      let id =
        this.purpose.matrix === 0
          ? this.purpose.id
          : this.purpose.id_purpose_main;
      const payload = {
        items: [
          {
            id_event: this.selectedEvent,
            id_purpose: id,
            // id_cost_center: this.costCenter.length ? this.costCenter[0].id_cost_center : null,
            id_cost_center: 84,
            justification: this.justification
          }
        ]
      };

      if (
        !payload.items[0].id_event ||
        !payload.items[0].id_purpose ||
        !payload.items[0].id_cost_center
      ) {
        return;
      }

      try {
        this.loadingApproval = true;
        if (option === 1) {
          const res = await this.$http.post(
            this.$ipApprovalFlow + "event-status-cost-center/approve",
            { ...payload }
          );
          if (res) {
            this.$toast.success(res.data.msg);
          }
        } else {
          const res = await this.$http.post(
            this.$ipApprovalFlow + "event-status-cost-center/disapprove",
            { ...payload }
          );
          if (res) {
            this.$toast.success(res.data.msg);
          }
        }
      } catch (err) {
        this.$fnError(err);
      }
      this.dialogApproval = false;
      this.loadingApproval = false;
      this.justification = "";
      this.refresh();
    },

    async approvalListLevels() {
      let unapprovedLevels = [];
      let id =
        this.purpose.matrix === 0
          ? this.purpose.id
          : this.purpose.id_purpose_main;
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_event",
              operator: "=",
              value: this.event ? this.event.id : null
            },
            {
              AndOr: "AND",
              column: "id_purpose",
              operator: "=",
              value: id
            },
            {
              AndOr: "AND",
              column: "id_cost_center",
              operator: "=",
              value: 84
            }
          ]
        }
      };

      if (payload.filter.conditions.find(condition => !condition.value)) {
        return;
      }

      try {
        const res = await this.$http.post(
          this.$ipApprovalFlow + "event-status-cost-center/list-levels",
          { ...payload }
        );
        if (res.data.rows.length > 0) {
          this.levels = res.data.rows;
          this.levels.forEach(level => {
            level.date
              ? (level.date = moment(level.date).format("DD/MM/YYYY HH:mm:ss"))
              : false;
            level.id_status === 5 || !level.id_status
              ? unapprovedLevels.push(level)
              : false;
          });
          unapprovedLevels.length < 1
            ? (this.actualLevel = this.levels[this.levels.length - 1])
            : (this.actualLevel = unapprovedLevels[0]);
          this.userGroups.forEach(group => {
            if (group.id_group === this.actualLevel.id_group) {
              this.disableApprove = false;
            }
          });
          await this.getlevels();
        } else {
          this.readOnlyApproval = false;
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async approvalHistory() {
      let id =
        this.purpose.matrix === 0
          ? this.purpose.id
          : this.purpose.id_purpose_main;
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_event",
              operator: "=",
              value: this.event.id
            },
            {
              AndOr: "AND",
              column: "id_purpose",
              operator: "=",
              value: id
            },
            {
              AndOr: "AND",
              column: "id_cost_center",
              operator: "=",
              value: 84
            }
          ]
        }
      };

      if (payload.filter.conditions.find(condition => !condition.value)) {
        return;
      }

      try {
        const res = await this.$http.post(
          this.$ipApprovalFlow + "event-status-cost-center/list-history",
          { ...payload }
        );
        if (res) {
          this.approvals = res.data.rows;
          this.approvals.forEach(approval => {
            approval.date
              ? (approval.date = moment(approval.date).format(
                  "DD/MM/YYYY HH:mm:ss"
                ))
              : false;
          });
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async listUserGroups() {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_user",
              operator: "=",
              value: this.getUser.id_user
            }
          ]
        }
      };
      try {
        const res = await this.$http.post(
          this.$ipSecurity + "user-group/list",
          { ...payload }
        );
        if (res) {
          this.userGroups = res.data.rows;
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async getlevels() {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_approval_flow_level",
              operator: "=",
              value: this.actualLevel.id_approval_flow_level
            }
          ]
        }
      };
      try {
        const res = await this.$http.post(
          this.$ipApprovalFlow + "approval-flow-level/list",
          { ...payload }
        );
        if (res) {
          if (res.data.rows.length > 0) {
            res.data.rows[0].change_values === 1
              ? (this.actualLevel.readOnly = false)
              : (this.actualLevel.readOnly = true);
          }
          if (
            this.actualLevel.id_level > 0 &&
            this.actualLevel.id_status !== 4
          ) {
            this.readOnlyApproval = this.actualLevel.readOnly;
            this.readOnlyApproval === true
              ? this.$toast.warning(
                  "Este nível de aprovação não permite alterações"
                )
              : false;
          } else {
            this.levels.length > 0
              ? (this.readOnlyApproval = true)
              : (this.readOnlyApproval = false);
            if (this.readOnlyApproval) {
              this.$toast.success(
                "Este evento está com todos os níveis aprovados"
              );
            }
          }
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    closeDialogApprovalAll() {
      if (this.doRefresh) {
        this.dialogApprovalAll = false;
        this.doRefresh = false;
        this.refresh();
      } else {
        this.dialogApprovalAll = false;
        this.selectedBP = null;
        this.allActualLevels = this.allActualLevelsUnfiltered;
      }
    },

    async approveAll(option) {
      const groupToApproval =
        this.selected.length > 0 ? this.selected : this.allActualLevels;
      this.loadingApproval = true;
      try {
        const payload = { items: [] };
        groupToApproval.forEach(() => {
          payload.items.push({
            id_event: this.selectedEvent,
            id_purpose: this.selectedPurpose ? this.selectedPurpose : null,
            id_cost_center: 84,
            justification: this.justification
          });
        });
        if (option === 1) {
          await this.$http.post(
            this.$ipApprovalFlow + "event-status-cost-center/approve",
            { ...payload }
          );
        } else {
          await this.$http.post(
            this.$ipApprovalFlow + "event-status-cost-center/disapprove",
            { ...payload }
          );
        }
        this.$toast.success("Centros de custo aprovados com successo");
      } catch (err) {
        this.$fnError(err);
      }
      this.loadingApproval = false;
      this.justification = "";
      this.selected = [];
      this.selectAllToApprove = false
      this.selectAllToReprove = false
      this.refresh();
    },

    async setAllLevels() {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_event",
              operator: "=",
              value: this.selectedEvent
            },
            {
              AndOr: "AND",
              column: "id_purpose",
              operator: "=",
              value: this.selectedPurpose ? this.selectedPurpose : null
            }
          ]
        }
      };
      try {
        const res = await this.$http.post(
          this.$ipApprovalFlow + "event-status-cost-center/list-levels",
          { ...payload }
        );
        if (res.data.rows.length > 0) {
          let levels = res.data.rows;
          let costCenters = this.costCenterOption.items.filter(
            costCenter => costCenter.id_purpose === this.selectedPurpose
          );
          costCenters = costCenters.map(costCenter => ({
            ...costCenter,
            levels: levels.filter(
              level => level.id_cost_center === costCenter.id_cost_center
            )
          }));
          costCenters = costCenters.map(costCenter => ({
            ...costCenter,
            unapprovedLevels: costCenter.levels.filter(
              level => level.id_status === 5 || !level.id_status
            )
          }));
          costCenters = costCenters.map(costCenter => ({
            ...costCenter,
            // VERIFICA SE TEM NÍVEL POR APROVAR
            actualLevel:
              costCenter.unapprovedLevels.length > 0
                ? {
                    ...costCenter.unapprovedLevels[0],
                    enable: this.userGroups.find(
                      group =>
                        group.id_group ===
                        costCenter.unapprovedLevels[0].id_group
                    )
                      ? true
                      : false
                  }
                : // VERIFICA SE TODOS NÍVEIS ESTÃO APROVADOS
                costCenter.levels.length > 0
                ? {
                    ...costCenter.levels[costCenter.levels.length - 1],
                    enable: this.userGroups.find(
                      group =>
                        group.id_group ===
                        costCenter.levels[costCenter.levels.length - 1].id_group
                    )
                      ? true
                      : false
                  }
                : //NÃO TEM NÍVEL CADASTRADO
                  { approval_flow_level_description: "Nenhum", enable: false }
            //O ENABLE HABILITA OU DESABILITA BOTÃO DE APROVAR E DESAPROVAR
          }));
          this.allActualLevels = costCenters;
          this.allActualLevelsUnfiltered = this.allActualLevels;
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async getBPs() {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_sales_force_type",
              operator: "=",
              value: 7
            }
          ]
        }
      };
      try {
        this.loadingBPs = true;
        const res = await this.$http.post(
          this.$ipSales + "sales-force/list-options",
          { ...payload }
        );
        if (res) {
          this.salesForceItems = res.data.rows;
          this.loadingBPs = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.loadingBPs = false;
      }
    },

    filterActualLevels() {
      if (this.selectedBP)
        this.allActualLevels = this.allActualLevelsUnfiltered.filter(
          level => level.id_sales_force_bp === this.selectedBP.id
        );
    },

    async runRatio(item, all) {
      !all ? (this.loadingRatio = true) : false;
      try {
        const payload = {
          id_event: this.selectedEvent,
          id_purpose: item.id,
          month: this.ratioMonth
        };

        const res = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning" + "/calculate-ratio",
          { ...payload }
        );

        if (res && !all) {
          this.$toast.success(res.data.msg);
          this.loadingRatio = false;
          this.doRefresh = true;
          this.ratioMonth = null;
          this.dialogRatio = false;
          this.refresh();
        }
      } catch (err) {
        this.loadingRatio = false;
        this.$fnError(err);
      }
    },

    async runAllRatios() {
      this.loadingRatio = true;
      const ratios = this.purposeOption.items;
      for (let count = 0; count < ratios.length; count++) {
        await this.runRatio(ratios[count], true);
      }
      this.$toast.success("Rateios executados com successo");
      this.loadingRatio = false;
      this.dialogRatio = false;
      this.refresh();
    },

    async deleteRatio(item, all) {
      this.loadingRatio = true;

      try {
        const payload = {
          id_event: this.selectedEvent,
          id_purpose: item.id
        };

        const res = await this.$http.post(
          this.$ipOpexPlanning +
            "opex-employee-planning" +
            "/delete-by-event-purpose-id",
          { ...payload }
        );

        if (res && !all) {
          this.$toast.success(res.data.msg);
          this.doRefresh = true;
          this.loadingRatio = false;
          // this.dialogRatio = false
          // this.refresh()
        }
      } catch (err) {
        this.loadingRatio = false;
        this.$fnError(err);
      }
    },

    async deleteAllRatios() {
      this.loadingRatio = true;
      const ratios = this.purposeOption.items;
      for (let count = 0; count < ratios.length; count++) {
        await this.deleteRatio(ratios[count], true);
      }
      this.$toast.success("Rateios deletados com successo");
      this.loadingRatio = false;
      this.dialogRatio = false;
      this.refresh();
    },

    closeDialogRatio() {
      if (this.doRefresh) {
        this.dialogRatio = false;
        this.doRefresh = false;
        this.refresh();
      } else {
        this.dialogRatio = false;
      }
    },

    async approveRatio(item, all) {
      !all ? (this.loadingRatio = true) : false;
      try {
        const payload = {
          id_event: this.selectedEvent,
          id_purpose: item.id
        };

        const res = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning" + "/approval-ratio",
          { ...payload }
        );

        if (res && !all) {
          this.$toast.success(res.data.msg);
          this.loadingRatio = false;
          this.doRefresh = true;
        }
      } catch (err) {
        this.loadingRatio = false;
        this.$fnError(err);
      }
    },

    async approveAllRatios() {
      this.loadingRatio = true;
      const ratios = this.purposeOption.items;
      for (let count = 0; count < ratios.length; count++) {
        await this.approveRatio(ratios[count], true);
      }
      this.$toast.success("Rateios aprovados com successo");
      this.loadingRatio = false;
      this.dialogRatio = false;
      this.refresh();
    },

    async updateMonths() {
      const payload = {
        id_event: this.event.id
      };
      try {
        this.loadingUpdateMonths = true;
        const res = await this.$http.post(
          this.$ipOpexPlanning + "opex-employee-planning/copy-from-actual",
          { ...payload }
        );
        if (res) {
          this.$toast.success(res.data.msg);
          this.loadingUpdateMonths = false;
          this.updateMonthsDialog = false;
        }
      } catch (err) {
        this.$fnError(err);
        this.loadingUpdateMonths = false;
      }
      this.loadingUpdateMonths = false;
      this.updateMonthsDialog = false;
      this.refresh();
    },

    loadMonths() {
      let months = [];
      moment.locale("pt-bt");
      months = moment.months();
      months.forEach((month, index) => {
        this.ratioMonths.push({
          id: index + 1,
          text: month.charAt(0).toUpperCase() + month.slice(1)
        });
      });
    }
  }
};
</script>

<style scoped>
div.v-tab {
  font-size: 12px;
}

div.v-tab.v-tab--active {
  font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
  font-weight: bold;
  color: var(--primary-color);
}

div.v-tab:hover span {
  font-weight: bold;
  color: black;
}

.v-menu__content {
  max-width: 350px !important;
}

.centered-input >>> input {
  text-align: center
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
